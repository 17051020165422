import { actionTypes } from '../constants/actionTypes';

const initialUnUsedSpins = [];

export const unUsedSpinsReducer = (state = initialUnUsedSpins, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_UNUSED_SPIN_LIST:
      return Array.isArray(payload) ? [...payload] : [...state];
    default:
      return state;
  }
};
