import { requestResults } from 'constants/common';
import { useAuthContext } from 'context/auth/authContext';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useUser } from 'hooks/user/useUser';
import { useState } from 'react';
import PaymentService from 'services/payment.service';
import { generateHeaders } from 'utils/utils';

export const usePayment = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { removeUser } = useUser();
  const [error, setError] = useState('');
  const { userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const { setHasAccessToken } = useAuthContext();
  const initHeaders = generateHeaders(userToken());

  const payIn: any = async (methodId: string, body: any, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status, message, error },
      } = await PaymentService.payIn(headers, methodId, body);

      return { status, data, message, error };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await payIn(methodId, body, newHeader);
        } else {
          console.log('LOG____> payIn', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const payOut: any = async (methodId: string, body: any, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await PaymentService.payOut(headers, methodId, body);

      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await payOut(methodId, body, newHeader);
        } else {
          console.log('LOG____> payOut', message);
          const mes = message as string;
          setError(requestResults[mes] ?? mes);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const payInProcess: any = async (id: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await PaymentService.payInProcess(headers, id);

      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await payInProcess(id, newHeader);
        } else {
          console.log('LOG____> payOut', message);
          const mes = message as string;
          setError(requestResults[mes] ?? mes);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const payInProccesCancel: any = async (id: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await PaymentService.payInProccesCancel(headers, id);

      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await payInProccesCancel(id, newHeader);
        } else {
          console.log('LOG____> payOut', message);
          const mes = message as string;
          setError(requestResults[mes] ?? mes);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const payInProccesConfirm: any = async (id: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status, message },
      } = await PaymentService.payInProccesConfirm(headers, id);

      return { status, data, message };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await payInProccesConfirm(id, newHeader);
        } else {
          console.log('LOG____> payOut', message);
          const mes = message as string;
          setError(requestResults[mes] ?? mes);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const payInProccesApprove: any = async (id: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status, message },
      } = await PaymentService.payInProccesApprove(headers, id);

      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await payInProccesApprove(id, newHeader);
        } else {
          console.log('LOG____> payOut', message);
          const mes = message as string;
          setError(requestResults[mes] ?? mes);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  return {
    isLoading,
    payInProccesCancel,
    payInProccesConfirm,
    payInProcess,
    payInProccesApprove,
    payOut,
    error,
    payIn,
    resetError: () => setError(''),
  };
};
