import { Box, Button, IconButton } from '@mui/material';
import cx from 'classnames';
import Backdrop from '@mui/material/Backdrop';
import Dialogs from 'components/dialog/dialogs';
import { useTranslation } from 'react-i18next';
import { activeTab, modalTypesObj } from 'components/navBar/navBar';
import { useModalContext } from 'context/modal/modalContext';
import { useDispatch, useSelector } from 'react-redux';
import { setWelcome } from 'redux/actions/welcomeAction';
import { GameCarousel } from './games/gameCarousel';
import { dialog } from 'constants/common';
import { useState } from 'react';
import { useSpins } from 'hooks/user/useSpins';
import CloseIcon from '@mui/icons-material/Close';
import { routing } from 'constants/routing';
import { useNavigate } from 'react-router-dom';
import LoaderWithBg from 'components/loader/loader';
import styles from './spinPopUp.module.scss';

interface SpinPopUpProps {
  onClose: (data: boolean) => void;
}

export const SpinPopUp = ({ onClose }: SpinPopUpProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { playSpins, isLoading } = useSpins();

  const [isOpenCancel, setOpenCancel] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState('');
  const unUsedSpins = useSelector<any>((state) => state.unUsedSpins);

  const { openModalWithData } = useModalContext();
  const { t } = useTranslation('common');

  const navigatePlay = (id: string, data: any) => {
    const state = {
      ...data,
      isSpinPlay: true,
    };
    navigate(`${routing.game}`, {
      state,
    });
  };

  const handleOnConfirm = async (id: string) => {
    const result = await playSpins(id);
    if (result) {
      onClose(false);
      navigatePlay(id, result.data);
    }
  };

  const handelClick = () => {
    dispatch(setWelcome(null));
    openModalWithData(modalTypesObj['deposit'], { activeTab: activeTab['spins'] });
    onClose(false);
  };

  const handleClose = () => {
    onClose(false);
  };

  const getId = (id: string) => {
    setOpenCancel(true);
    setSelectedGameId(id);
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box className={styles.main}>
        <Box className={styles.banner}>
          <section className={cx(styles.wrapper, styles.anounce__freespins)}>
            <IconButton className={styles.close_btn} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            <div className={styles.anoounce__left}>
              <h2 className={styles.anounce__title}>
                Вы получили
                <span className={styles.anounce__highlight}>
                  {/* <img className={styles.anounce__highlight_icon} src={crypto} alt="crypto" />{' '} */}
                  бесплатные спины!
                </span>
              </h2>

              <div className={styles.anounce__description}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box className={styles.anounce__image}>
                    <GameCarousel onClick={getId} spinList={(unUsedSpins as any[]) ?? []} />
                  </Box>
                </Box>
              </div>
              <span className={styles.anounce__highlight_small}>
                Не упустите возможность испытать удачу!
              </span>
            </div>
          </section>
          <Box className={styles.footer}>
            <Button className={styles.btn} onClick={handelClick}>
              Активировать
            </Button>
          </Box>
        </Box>
      </Box>
      {isOpenCancel ? (
        <Dialogs
          id={selectedGameId}
          title={t(dialog.CANCEL_BONUS)}
          content={t(dialog.SPIN_TEXT)}
          subContent={t('subcontent')}
          isOpen={isOpenCancel}
          onClose={() => setOpenCancel(false)}
          onConfirm={handleOnConfirm}
          btnText={t('play')}
          ignoreBtnText={t('cancel')}
          contentStyle={{ padding: '0 12px' }}
        />
      ) : null}
    </Backdrop>
  );
};
