import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'utils/validations/validationTypes';
import { useTransaction } from 'hooks/transactions/useTransaction';
import { useSelector } from 'react-redux';
import { usePayment } from 'hooks/payment/usePayment';
import { FIELD_NAME, IPayMethods } from 'types/payment';
import magnitXPay from 'assets/payment/magnitXPay.webp';
import tg from 'assets/bonuses/terms/3.webp';
import common from 'styles/colors.module.scss';
import _color from 'styles/colors.module.scss';
import React from 'react';
import Dialogs from 'components/dialog/dialogsWithImage';
import LoaderWithBg from 'components/loader/loader';
import DialogAlert from 'components/dialog/dialogAlert';
import { RESULT } from 'types/common';
import styles from './deposit.module.scss';

const fields = {
  [FIELD_NAME.MIN]: true,
};

export const renderItem = (
  isActive: boolean,
  item: IPayMethods,
  typo: string,
  index: number,
  min: number,
  curr: string,
  callBack: (data: IPayMethods) => void,
) => {
  const { image } = item;

  const handleOnClick = () => {
    callBack(item);
  };

  return (
    <Box
      className={cx(styles.deposit_item, { [styles.isActive]: isActive })}
      key={index}
      onClick={handleOnClick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={image} alt="deposit" />
      </Box>
      <Box sx={{ display: 'flex', bottom: '11px', position: 'absolute' }}>
        <Typography component="span" sx={{ mr: 0.5 }}>
          {typo}
        </Typography>

        <Typography component="span">
          {min} {curr}
        </Typography>
      </Box>
    </Box>
  );
};

function Deposit() {
  const { t } = useTranslation('deposit');
  const { t: commonT } = useTranslation('common');
  const { t: paymentT } = useTranslation('payment');
  const { t: validationT } = useTranslation('validation');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDialogAlert, setIsOpenDialogAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [withBtn, setWithBtn] = useState(false);
  const [isNeedDepositRequest, setIsNeedDepositRequest] = useState(false);
  const [contentText, setContentText] = useState('');
  const [url, setURL] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [value] = useState('0');
  const [tgLink, setTgLink] = useState('');
  const userData = useSelector<any>((state) => state.userData) as any;
  const { isLoadingPayIns, methodsList, getAllPayInMethods } = useTransaction();
  const { isLoading, payIn } = usePayment();
  const [activePayment, setActivePayment] = useState<IPayMethods>();
  const [quantity, setQuantity] = useState('');
  const [validFields, setValidFields] = useState({ ...fields });
  const [errorName, setErrorName] = useState('');
  const [nativeMethod, setNativePayment] = useState<IPayMethods>();
  const elementRef = useRef<HTMLDivElement>(null);

  const walletAmount = userData?.balance.filter((el: any) => el.id === 3)?.[0]?.amount;

  const resetValidFields = () => {
    setErrorName('');
    setValidFields((fields) => ({
      ...fields,
      [FIELD_NAME.MIN]: true,
    }));
  };

  const checkValidation = (activePayment: IPayMethods, name: string, value: string) => {
    let isValid = true;

    switch (name) {
      case FIELD_NAME.CARD:
        isValid = value.length === 19;
        break;
      case FIELD_NAME.MIN:
        if (+value >= activePayment?.sitePayInMethods?.[0]?.maxValue) {
          setErrorName('isBiggerMaxVal');
        } else if (+value <= activePayment?.sitePayInMethods?.[0]?.minValue) {
          setErrorName('isSmallerMinVal');
        }
        isValid =
          +value >= activePayment?.sitePayInMethods?.[0]?.minValue &&
          +value <= activePayment?.sitePayInMethods?.[0]?.maxValue;

        break;
      default:
    }
    return isValid;
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;

    if (activePayment && value !== '') {
      setValidFields((fields) => ({
        ...fields,
        [name]: checkValidation(activePayment, name, value),
      }));
    }
  };

  const handleOnClick = async () => {
    if (!isLoading) {
      if (activePayment && +quantity !== 0 && validFields[FIELD_NAME.MIN]) {
        if (!activePayment?.isNative) {
          makeRequest(+quantity);
        } else {
          setIsOpen(true);
          setTgLink(activePayment?.link ?? '');
        }
      } else if (quantity === '') {
        setValidFields((fields) => ({
          ...fields,
          [FIELD_NAME.MIN]: false,
        }));
      }
    }
  };

  const handleOnChangeMin = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (isNumber(value)) {
      setQuantity(value);
    }

    resetValidFields();
  };

  const changeActivePayment = (item?: IPayMethods) => {
    if (item) {
      setActivePayment(item);
    }
    setQuantity('');
    setValidFields({ ...fields });
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const getData = async (isCripto: boolean) => {
    await getAllPayInMethods(`isCrypto=${isCripto}`);
  };

  const makeRequest = async (quantity: number) => {
    const body = { amount: quantity };
    setShowLoader(true);
    const res = await payIn(activePayment?.id, body);
    if (res?.status === 200) {
      window.open(res.data.metadata.paymentURL, '_self');
    } else if (res?.status === 403) {
      const text =
        res?.message === 'create_pay_in_process_block_error'
          ? RESULT.REQUEST_BLOCK
          : RESULT.REQUISITES_ERROR;
      setContentText(paymentT(text));
      setIsOpenDialogAlert(true);
    } else if (res?.status === 409) {
      const text = 'У вас уже есть открытая транзакция. Хотите продолжить ?';
      setContentText(paymentT(text));
      setIsOpenDialogAlert(true);
      setURL(res.data);
    } else if (res?.status === 400) {
      setContentText(paymentT(res?.message, { amount: res?.error?.details }));
      setIsOpenDialogAlert(true);
      setIsNeedDepositRequest(true);
      setDepositAmount(res?.error?.details);
      setWithBtn(true);
      setURL('');
    }
  };

  const handleOnClose = () => {
    changeActivePayment();
    setIsOpenDialogAlert(false);
    setShowLoader(false);
  };

  const handleOnConfirm = (url: string) => {
    window.open(url, '_self');
  };

  const handeDoRequest = () => {
    if (isNeedDepositRequest) {
      makeRequest(+depositAmount);
    }
  };

  useMemo(() => {
    setNativePayment(methodsList.filter((el: IPayMethods) => el.link)?.[0] ?? {});
  }, [methodsList]);

  useEffect(() => {
    getData(!!+value);
  }, [value]);

  useEffect(() => {
    if (methodsList.length) {
      setActivePayment(methodsList[0]);
    }
  }, [methodsList]);

  return (
    <Box className={styles.main_area}>
      {showLoader || isLoading ? <LoaderWithBg isOpen={showLoader || isLoading} /> : null}
      <Box className={styles.main_title}>{t('wallet')}</Box>
      <Box className={styles.methods_block}>
        <Box className={styles.header}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box className={styles.title}>{paymentT('title')}</Box>
            <Box className={styles.bullet} />
          </Box>
          <Box className={styles.current_amount}>
            <Typography>{t('currAmount')}:</Typography>
            <Typography sx={{ color: common.mainOrange, ml: 2, fontWeight: 800 }}>
              {walletAmount} {userData?.currency?.code}
            </Typography>
          </Box>
        </Box>
        <TabContext value={value}>
          <TabPanel value="0" className={styles.own_tab}>
            {isLoadingPayIns ? (
              <Box className={styles.loading}>{commonT('loading')}</Box>
            ) : (
              <>
                <Box className={styles.deposit_block}>
                  {nativeMethod ? (
                    <Box
                      className={cx(styles.deposit_item, {
                        [styles.isActive]: activePayment?.isNative,
                      })}
                      onClick={() => changeActivePayment(nativeMethod)}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={magnitXPay} alt="magnitXPay" />
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography component="span" sx={{ mr: 0.5 }}>
                          {t('payNative')}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', bottom: '11px', position: 'absolute' }}>
                        <Typography component="span">
                          {t('min')} {nativeMethod?.sitePayInMethods?.[0]?.minValue ?? ''}{' '}
                          {nativeMethod?.sitePayInMethods?.[0]?.currencyCode ?? ''}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {methodsList
                    .filter((el: any) => !el.isNative)
                    .map((item: IPayMethods, index: number) => {
                      return renderItem(
                        activePayment?.id === item.id,
                        item,
                        t('min'),
                        index,
                        item.sitePayInMethods[0].minValue,
                        item.sitePayInMethods[0].currencyCode,
                        changeActivePayment,
                      );
                    })}
                </Box>
              </>
            )}
          </TabPanel>
        </TabContext>
        <Box>
          <Box className={styles.promo} ref={elementRef}>
            <TextField
              label={t('count', {
                val: activePayment?.sitePayInMethods[0].minValue,
                curr: activePayment?.sitePayInMethods[0].currencyCode,
              })}
              size="small"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={quantity}
              onBlur={(e) => validate(e, FIELD_NAME.MIN)}
              error={!validFields[FIELD_NAME.MIN]}
              onChange={handleOnChangeMin}
            />
            <Box className={styles.currency_name}>{userData?.currency?.code}</Box>
          </Box>
          {!validFields[FIELD_NAME.MIN] && errorName === 'isSmallerMinVal' ? (
            <Typography className={styles.error}>
              {validationT('minMustBe', { length: activePayment?.sitePayInMethods[0].minValue })}{' '}
              {commonT('forDeposit')}
            </Typography>
          ) : !validFields[FIELD_NAME.MIN] && errorName === 'isBiggerMaxVal' ? (
            <Typography className={styles.error}>
              {validationT('maxMustBe', { length: activePayment?.sitePayInMethods[0].maxValue })}{' '}
              {commonT('forDeposit')}
            </Typography>
          ) : null}
          <Button
            sx={{ mt: 2, backgroundColor: common.darkGreen }}
            fullWidth
            variant="contained"
            onClick={handleOnClick}
            disabled={!validFields[FIELD_NAME.MIN]}
          >
            {t('deposit')}
          </Button>
        </Box>
      </Box>
      <Dialogs
        title={''}
        content={paymentT('volkText')}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        btnText={'Написать'}
        contentStyle={{ padding: '0 12px' }}
        imageContent={tg}
        ignoreBtnText="hide"
        className={styles.volk_deposit_info}
        hasLink={tgLink}
      />
      <DialogAlert
        severity={'warning'}
        content={contentText}
        isOpen={isOpenDialogAlert}
        onClose={handleOnClose}
        onConfirm={handleOnConfirm}
        withBtn={withBtn}
        url={url}
        btnText={commonT('yes')}
        cancelBtnText={commonT('no')}
        isNeedDepositRequest={isNeedDepositRequest}
        doRequest={handeDoRequest}
      />
    </Box>
  );
}

export default Deposit;
