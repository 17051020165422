// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Backdrop from '@mui/material/Backdrop';
import { Box, Button, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import _color from 'styles/colors.module.scss';
import styles from './captcha.module.scss';

interface CaptchaProps {
  onHandleRecaptcha: (status: boolean) => void;
}
const generateCaptchaText = () => {
  const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'; // Исключаем похожие символы
  let captcha = '';
  for (let i = 0; i < 5; i++) {
    captcha += chars[Math.floor(Math.random() * chars.length)];
  }
  return captcha;
};

const Captcha = ({ onHandleRecaptcha }: CaptchaProps) => {
  const [captchaText, setCaptchaText] = useState(generateCaptchaText());
  const [input, setInput] = useState('');
  const [message, setMessage] = useState('');
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = '22px Arial';
    ctx.fillStyle = _color.mainOrange;
    ctx.fillText(captchaText, 10, 20);

    // Добавляем шум (линии)
    for (let i = 0; i < 5; i++) {
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.stroke();
    }
  }, [captchaText]);

  const handleCheckCaptcha = () => {
    if (input.toUpperCase() === captchaText) {
      setMessage('✅ CAPTCHA введена правильно!');
      onHandleRecaptcha(input.toUpperCase() === captchaText);
    } else {
      setMessage('❌ Ошибка! Попробуйте снова.');
      setCaptchaText(generateCaptchaText()); // Генерируем новую CAPTCHA
    }
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <Box className={styles.captcha}>
        <Typography component={'h4'}>Введите код с картинки</Typography>
        <Box className={styles.content}>
          <canvas ref={canvasRef} width={100} height={30} />
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Введите код"
          />
          <Button color="success" onClick={handleCheckCaptcha}>
            Проверить
          </Button>
        </Box>
        <Typography>{message}</Typography>
      </Box>
    </Backdrop>
  );
};

export default Captcha;
