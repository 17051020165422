import React from 'react';
import { Box, Typography, Button, MenuItem } from '@mui/material';
import LanguageMenu from 'components/languageMenu/languageMenu';
import cx from 'classnames';
import logoMobile from 'assets/logo/logo.webp';
import { useTranslation } from 'react-i18next';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useAuthentication } from 'hooks/authHooks/useAuthentication';
import { ReactComponent as Deposit } from 'assets/icons/menuIcons/deposit.svg';
import { useState } from 'react';
import Signup from 'components/signup/signup';
import { FIELD_NAME } from 'types/login';
import { activeTab } from 'components/navBar/navBar';
import Login from 'components/login/login';
import { Link, useNavigate } from 'react-router-dom';
import { useModalContext } from 'context/modal/modalContext';
import { routing } from 'constants/routing';
import { main_menu } from 'components/navBar/mocked_data';
import { MODAL_TYPES } from 'types/modals';
import _color from 'styles/colors.module.scss';
import styles from './menuMobile.module.scss';

export const menu_mobile_secondary = [
  { id: '8', menu: 'deposit', link: '/deposit', icon: <Deposit /> },
];

interface MenuMobileProps {
  onCloseNavMenu: () => void;
  user: any;
}

export const MenuMobile = ({ onCloseNavMenu, user }: MenuMobileProps) => {
  const { t } = useTranslation('menu');
  const { t: loginAndSignupT } = useTranslation('loginAndSignup');
  const { hasToken } = useAuthentication();
  const { openModalWithData } = useModalContext();
  const [show, setShow] = useState({ login: false, signup: false });
  const navigate = useNavigate();
  const handleOnOpen = (name: string) => {
    setShow((data) => ({ ...data, [name]: true }));
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>, type: string, link: string) => {
    event.preventDefault();
    if (
      type === 'balance' ||
      type === 'wheel' ||
      type == 'deposit' ||
      type === 'myBonuses' ||
      type === 'live'
    ) {
      if (!user) {
        openModalWithData(
          FIELD_NAME.LOGIN,
          type === 'wheel'
            ? {
                redirect: routing.wheel,
              }
            : type === 'live'
              ? { redirect: routing.live }
              : null,
        );
      } else if (type === 'balance') {
        openModalWithData(MODAL_TYPES.DEPOSIT, null);
      } else if (type === 'deposit') {
        openModalWithData(MODAL_TYPES.DEPOSIT, { activeTab: activeTab[MODAL_TYPES.DEPOSIT] });
      } else if (type === 'myBonuses') {
        openModalWithData(MODAL_TYPES.DEPOSIT, { activeTab: activeTab['myBonuses'] });
      } else {
        navigate(link);
      }
    } else {
      navigate(link);
    }
  };

  const handleOnSwitchComponent = (name: string) => {
    setShow({ login: false, signup: false, [name]: true });
  };

  const handleOnClose = () => {
    setShow((data) => ({ ...data, login: false, signup: false }));
  };

  return (
    <>
      {!(show.login || show.signup) ? (
        <>
          <Box className={styles.top_mobile}>
            <Box sx={{ mb: 2 }}>
              <img src={logoMobile} alt="logo" width={128} className={styles.img_logo} />
            </Box>
            <Typography className={styles.anchor_title} textAlign="left" sx={{ mb: 4 }}>
              {t('menu')}
            </Typography>

            <Box className={cx(styles.action_btn, { [styles.hasToken]: user })}>
              {!user ? (
                <>
                  <Button
                    onClick={() => handleOnOpen(FIELD_NAME.LOGIN)}
                    className={styles.login_btn}
                  >
                    {loginAndSignupT('login')}
                  </Button>
                  <Button
                    onClick={() => handleOnOpen(FIELD_NAME.SIGN_UP)}
                    className={styles.sign_btn}
                  >
                    {loginAndSignupT('signup')}
                  </Button>
                </>
              ) : (
                <>
                  <Box sx={{ p: 0, width: '100%', display: 'flex', alignItems: 'center' }}>
                    <AccountBoxIcon sx={{ mr: 1, fontSize: 32, fill: _color.mainOrange }} />
                    <Typography component="span" className={styles.anchor_title} textAlign="left">
                      {user?.user?.login}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box>
            {(!user ? main_menu.filter((el) => el.menu !== 'myBonuses') : main_menu).map(
              ({ menu, icon, link }, index) => {
                if (menu !== 'favorites' || hasToken) {
                  return (
                    <MenuItem
                      className={cx(styles.menu_mobile)}
                      key={index}
                      onClick={onCloseNavMenu}
                    >
                      <Link
                        to={link}
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(e) => handleOnClick(e, menu, link)}
                      >
                        {icon}
                        <Typography sx={{ ml: 2 }} textAlign="center">
                          {t(`${menu}`)}
                        </Typography>
                      </Link>
                    </MenuItem>
                  );
                }
              },
            )}
          </Box>
          <Box>
            {menu_mobile_secondary.map(({ menu, icon, link }, index) => (
              <MenuItem className={cx(styles.menu_mobile)} key={index} onClick={onCloseNavMenu}>
                <Link
                  to={link}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={(e) => handleOnClick(e, menu, link)}
                >
                  {icon}
                  <Typography sx={{ ml: 2 }} textAlign="center">
                    {t(`${menu}`)}
                  </Typography>
                </Link>
              </MenuItem>
            ))}
          </Box>
          <Box>
            <LanguageMenu isMobile />
          </Box>
        </>
      ) : show.login ? (
        <Login onClose={handleOnClose} onSwitchComponent={handleOnSwitchComponent} isMobile />
      ) : (
        <Signup onClose={handleOnClose} onSwitchComponent={handleOnSwitchComponent} isMobile />
      )}
    </>
  );
};
