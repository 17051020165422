export enum MODAL_TYPES {
  CHOOSE_BALANCE = 'chooseBalance',
  PROFILE = 'profile',
  HISTORY = 'history',
  DEPOSIT = 'deposit',
  WITHDRAW = 'withDraw',
  NOTIFICATION = 'notification',
  LEVELS = 'levels',
  LOGOUT = 'logout',
  SPINS = 'spins'
}

export enum MODAL_ACTIVE_TABS {
  BONUSES = '3',
  MY_BONUSES = '3',
  DEPOSIT = '1',
  BALANCE = '0',
  WITHDRAW = '2',
  VERIFICATION = '1',
}

export enum HISTORY_ITEM {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  BONUS = 'bonus',
  TRANSFER = 'transfer',
}