import { AlertColor, Box, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePromo } from 'hooks/user/usePromo';
import { useState } from 'react';
import { FIELD_NAME } from 'types/promo';
import { useSelector } from 'react-redux';
import Result from 'components/alert/alert';
import { RESULT } from 'types/common';
import { requestResults } from 'constants/common';
import LoaderWithBg from 'components/loader/loader';
import styles from './promo.module.scss';

const fields = {
  [FIELD_NAME.PROMO]: true,
};

function Promo() {
  const { t } = useTranslation('deposit');
  const { t: commonT } = useTranslation('common');
  const { t: validationT } = useTranslation('validation');
  const [promoCode, setPromoCode] = useState('');
  const [validFields, setValidFields] = useState({ ...fields });
  const [apiError, setApiError] = useState<string>('');
  const [type, setType] = useState<AlertColor>('success');

  const { isLoading, activateUserPromo, resetError, error } = usePromo();

  const userData = useSelector<any>((state) => state.userData) as any;

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setPromoCode(value);
    if (apiError || error) {
      resetError();
      setApiError('');
      setType('success');
    }
  };

  const handleOnClose = () => {
    resetError();
    setApiError('');
    setPromoCode('');
  };

  const handleOnClick = async () => {
    const body = { currencyId: userData?.currencyId ?? '' };
    const result = await activateUserPromo(promoCode, body);
    if (result?.data) {
      setType(RESULT.SUCCESS);
      setApiError(commonT(requestResults.PROMO_ACTIVATE_SUCCESS));
    } else {
      const message = result?.message ?? '';
      setType(RESULT.ERROR);
      const t =
        result?.error === 'Promo code not found'
          ? validationT('promoExpired')
          : (validationT(requestResults[message], { ...result?.error }) as string);
      setApiError(t);
    }
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;

    setValidFields((fields) => ({
      ...fields,
      [name]: value && value.length === 5,
    }));
  };

  return (
    <Box className={styles.main_area}>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box className={styles.main_title}>{t('promo')}</Box>
      <Box className={styles.promo_block}>
        <Box className={styles.promo}>
          <TextField
            value={promoCode}
            onChange={handleOnChange}
            onBlur={(e) => validate(e, FIELD_NAME.PROMO)}
            error={!validFields[FIELD_NAME.PROMO]}
            helperText={
              validFields[FIELD_NAME.PROMO] ? '' : validationT('minLength', { length: 5 })
            }
            size="small"
            fullWidth
            placeholder={t('placeholderPromo')}
            variant="outlined"
            sx={{ '& input::placeholder': { fontSize: '13px' } }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            sx={{ ml: 2, minWidth: '110px' }}
            variant="contained"
            onClick={handleOnClick}
            disabled={!validFields[FIELD_NAME.PROMO]}
          >
            {commonT('apply')}
          </Button>
        </Box>
      </Box>
      <Result
        message={commonT(requestResults[error]) || apiError}
        type={error ? RESULT.ERROR : type}
        isShow={!!(apiError || error)}
        hasAction
        onClose={handleOnClose}
      />
    </Box>
  );
}

export default Promo;
