import { Dialog, Alert, Button } from '@mui/material';
import { ReactNode } from 'react';
import _color from 'styles/colors.module.scss';

export type severityType = 'warning' | 'success' | 'error' | 'info';

interface IDialogAlertProps {
  contentStyle?: any;
  severity: severityType;
  content?: string | ReactNode;
  isOpen: boolean;
  btnText?: string;
  cancelBtnText?: string;
  withBtn?: boolean;
  isNeedDepositRequest?: boolean;
  url?: string;
  onClose: () => void;
  doRequest?: () => void;
  onConfirm?: (url: string) => void;
}

const DialogAlert = ({
  content,
  isOpen,
  severity,
  withBtn,
  btnText,
  cancelBtnText,
  isNeedDepositRequest,
  url,
  onClose,
  doRequest,
  onConfirm,
}: IDialogAlertProps) => {
  return (
    <Dialog open={isOpen} onClick={onClose}>
      <Alert variant="filled" severity={severity}>
        {content}
      </Alert>
      {withBtn || url ? (
        <>
          <Button
            sx={{
              backgroundColor: _color.darkGreen,
              color: 'white',
              justifyContent: 'center',
              mt: 1,
            }}
            onClick={isNeedDepositRequest ? () => doRequest?.() : () => onConfirm?.(url ?? '')}
          >
            {btnText ?? ''}
          </Button>
          {!url ? (
            <Button
              sx={{
                backgroundColor: _color.mainwarning,
                color: 'white',
                justifyContent: 'center',
                mt: 1,
              }}
              onClick={onClose}
            >
              {cancelBtnText ?? ''}
            </Button>
          ) : null}
        </>
      ) : null}
    </Dialog>
  );
};

export default DialogAlert;
