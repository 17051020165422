import { Box, Typography, IconButton, InputBase, Paper, Stack, Button } from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useAuthContext } from 'context/auth/authContext';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useCategory } from 'hooks/categoryGames/useCategory';
import Icon7 from 'assets/icons/defaultMenuIcons/7.svg';
import { useSelector } from 'react-redux';
import { IGame } from 'types/game';
import color from 'styles/colors.module.scss';
import GameCard from 'components/gameCard/gameCard';
import { useUserData } from 'hooks/user/useUserData';
import { makeQueryUrl } from 'utils/utils';
import { useSubProviderGames } from 'hooks/subProviderGames/useSubProviderGames';
import { useLocation } from 'react-router-dom';
import styles from './slots.module.scss';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root.Mui-selected, & .MuiPaginationItem-root.Mui-selected:hover': {
      color: color.mainOrange,
      backgroundColor: 'unset',
    },
  },
  paper: {
    height: '40px',
    border: 'Thin solid white',
    backgroundColor: 'unset!important',
    '& svg': {
      fill: 'white',
    },
  },
}));

interface IFilterData {
  subProviderId?: string;
  categoryId?: string;
  isFavorite?: boolean;
}

export const SlotsPage = () => {
  const { t } = useTranslation('common');
  const { state } = useLocation();
  const { user, hasAccessToken } = useAuthContext();
  const [debouncedValue, setDebouncedValue] = useState('');
  const [providerName, setProviderName] = useState('');
  const [gameName, setGameName] = useState<string | null>(null);
  const [filterData, setFilterData] = useState<IFilterData>({});
  const {
    isLoading: isLoadingSubProviders,
    getAllSubProviderGames,
    getAllSubProvidersByCategoryId,
  } = useSubProviderGames();

  const [globalTotalPages, setGlobalTotalPages] = useState(null);
  const [activeGames, setActiveGames] = useState<IGame[]>([]);
  const [page, setPage] = useState(1);
  const [activeIconMenu, setActiveIconMenu] = useState<string>('');

  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };
  const { isLoading, getAllCategoryAndAllGames } = useCategory();
  const { isLoading: isLoadingSpecialGames, getUsersRecentGames } = useUserData();

  const { categories } = useSelector<any>((state) => state.categories) as {
    categories: any;
  };
  const providersWithoutGames = useSelector<any>((state) => state.providersWithoutGames) as any;
  const [providersList, setProvidersList] = useState<any>([]);

  const [activeCategory, setActiveCategory] = useState<number | null>(
    state?.categoryId ?? categories?.[0]?.id,
  );
  const [selectedSubProvider, setSelectedSubProvider] = useState<string | null>(null);

  const classes = useStyles();
  // const tempProviderList = useRef<any>(providersWithoutGames);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleOnClickSubProvider = (subProviderId: string) => {
    const newData = { ...filterData };
    if ('isFavorite' in newData) {
      delete newData['isFavorite'];
    }
    setFilterData(newData);
    setSelectedSubProvider(subProviderId);
    setActiveIconMenu('');
    setActiveGames([]);
    setPage(1);
    setGameName(null);
  };

  const handleOnClickMore = (event: React.ChangeEvent<unknown>, totalPages: number) => {
    if (totalPages > 1) {
      setPage((value) => (value + 1 <= totalPages ? value + 1 : value));
    }
  };

  const handleOnClickCategory = (categoryId?: number) => {
    setFilterData({});
    setActiveGames([]);
    setActiveIconMenu('');
    setProviderName('');
    setGameName(null);
    setPage(1);
    if (categoryId) {
      setActiveCategory(categoryId as number);
      setSelectedSubProvider(null);
    }
    // else {
    //   setActiveCategory(null);
    //   setSelectedSubProvider(providersWithoutGames[0].id);
    //   setProvidersList(providersWithoutGames);
    // }
  };

  const bringIntoView = () => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => {
    setPage(1);

    const delay = 500;
    const timeoutId = setTimeout(() => {
      if (typeof gameName === 'string') {
        setActiveGames([]);
        setDebouncedValue(gameName);
      } else {
        setDebouncedValue('');
      }
    }, delay);

    if (device != 'Desktop' && page <= 1) {
      bringIntoView();
    }
    return () => clearTimeout(timeoutId);
  }, [gameName]);

  useEffect(() => {
    if (
      activeCategory ||
      Object.keys(filterData).length !== 0 ||
      selectedSubProvider ||
      debouncedValue
    ) {
      let query = debouncedValue ? `&name=${debouncedValue}` : '';
      query += activeCategory ? `&categoryId=${activeCategory}` : '';
      query += selectedSubProvider ? `&subProviderId=${selectedSubProvider}` : '';
      const deviceQuery =
        device === 'Mobile'
          ? `&isMobile=true&isGameCategoryMobile=true`
          : `&isDesktop=true&isGameCategoryDesktop=true`;
      const getData = async () => {
        query += `${makeQueryUrl(filterData)}&page=${page}${deviceQuery}&pageSize=24`;
        const newQuery = query.split('');
        newQuery.shift();
        query = newQuery.join('');
        const forPublic = !user ? '/public?' : undefined;
        const result = await getAllSubProviderGames(query, forPublic);
        if (result?.status === 200) {
          const { games, totalPages } = result.data;
          setGlobalTotalPages(totalPages);
          totalPages && totalPages > 1
            ? setActiveGames((activeGames) => (activeGames ? [...activeGames, ...games] : games))
            : setActiveGames(games);
          if (activeCategory) {
            const res = await getAllSubProvidersByCategoryId(activeCategory, deviceQuery);
            if (res?.status === 200) {
              const currentList =
                providersWithoutGames?.filter((elm: any) => res?.data?.includes(elm.id)) ?? [];
              setProvidersList(currentList);
              // tempProviderList.current = [...currentList];
              if (!selectedSubProvider) setSelectedSubProvider(null);
            }
          }
        } else if (result?.status === 404) {
          setGlobalTotalPages(null);
          setActiveGames([]);
        }
      };

      if (hasAccessToken || !user) getData();
    }
    if (device != 'Desktop' && page <= 1) {
      bringIntoView();
    }
  }, [hasAccessToken, filterData, page, debouncedValue, activeCategory, selectedSubProvider]);

  const changeActiveIconMenu = (name: string) => {
    setActiveIconMenu(name);
    setPage(1);
    if (name === 'favoriteGames') {
      setActiveGames([]);
      setFilterData((filterData) => ({ ...filterData, isFavorite: true }));
    } else {
      if (activeIconMenu !== 'resentGames') {
        setFilterData({});
        setSelectedSubProvider(null);
        setActiveCategory(0);
        const getData = async () => {
          const { data } = await getUsersRecentGames();
          setActiveGames(data);
          setGlobalTotalPages(null);
        };
        getData();
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleGameSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setGameName(value);
  };

  const handleProviderSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      setProvidersList(
        providersList.filter((elm: any) =>
          elm.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
        ),
      );
    }
    // else {
    //   activeCategory === null
    //     ? setProvidersList(providersWithoutGames)
    //     : setProvidersList(tempProviderList.current);
    // }
    setProviderName(value);
  };

  const getCategories = async (user: any, query: string) => {
    if (!user) {
      await getAllCategoryAndAllGames(query);
    } else if (hasAccessToken) {
      await getAllCategoryAndAllGames(query, '/user?');
    }
  };

  useEffect(() => {
    if (state?.categoryId && categories.length && providersWithoutGames.length) {
      setActiveCategory(state.categoryId);
    } else {
      // if (providersWithoutGames.length && categories.length && !state?.categoryId) {
      //   setSelectedSubProvider(providersWithoutGames[0]?.id);
      //   setProvidersList(providersWithoutGames);
      // } else

      if (!categories.length) {
        const isMobile = device === 'Mobile';
        const query = isMobile
          ? `isMobile=${isMobile}&isGameCategoryMobile=${isMobile}`
          : 'isDesktop=true&isGameCategoryDesktop=true';
        if (device) getCategories(user, query);
      }
    }
    if (device != 'Desktop' && page <= 1) {
      bringIntoView();
    }
  }, [categories, state, providersWithoutGames]);

  return (
    <Box className={styles.main}>
      <Box className={styles.left_side}>
        <Box className={styles.category_list}>
          {/* <Box
            className={cx(styles.category_logo, {
              [styles.active]: activeCategory === null,
            })}
            onClick={() => handleOnClickCategory()}
          >
            <img src={Icon7} alt="categoryIcon" width={28} />
            <Typography align="center">All</Typography>
          </Box> */}
          {categories?.map((category: any, index: number) => {
            return (
              <Box
                key={index}
                className={cx(styles.category_logo, {
                  [styles.active]: category.id === activeCategory,
                })}
                onClick={() => handleOnClickCategory(category.id)}
              >
                <img src={category.image ?? Icon7} alt="categoryIcon" width={28} />
                <Typography align="center">{category.title}</Typography>
              </Box>
            );
          })}
        </Box>

        <Box className={styles.provider_list}>
          <Paper
            onSubmit={handleSubmit}
            className={classes.paper}
            component="form"
            sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 3 }}
          >
            <InputBase
              size="small"
              fullWidth
              sx={{ ml: 1, flex: 1 }}
              placeholder={t('searchProv')}
              value={providerName}
              inputProps={{ 'aria-label': 'search prov' }}
              onChange={(e) => handleProviderSearch(e)}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>

          <Box className={styles.providers}>
            {providersList?.map((item: any, index: number) => {
              if (item.id)
                return (
                  <Box
                    key={index}
                    className={cx(styles.logo, {
                      [styles.active]: item.id === selectedSubProvider,
                    })}
                    onClick={() => handleOnClickSubProvider(item.id)}
                  >
                    <img src={item.image} alt={item.name} width={100} />
                  </Box>
                );
              return (
                <Box
                  key={index}
                  className={cx(styles.logo, {
                    [styles.active]: item.id === selectedSubProvider,
                  })}
                  onClick={() => handleOnClickSubProvider(item.id)}
                >
                  {item.name}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={styles.right_side} ref={elementRef}>
        <Box
          sx={{
            width: '100%',
            padding: '0px 24px 12px',
            mt: 3,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {user && (
            <Box className={styles.icon_menu}>
              <IconButton
                className={cx({
                  [styles.active]: activeIconMenu === 'resentGames',
                })}
                type="button"
                aria-label="star"
                onClick={() => changeActiveIconMenu('resentGames')}
              >
                <ScheduleIcon />
              </IconButton>
              <IconButton
                className={cx({
                  [styles.active]: activeIconMenu === 'favoriteGames',
                })}
                type="button"
                aria-label="favorite"
                onClick={() => changeActiveIconMenu('favoriteGames')}
              >
                <FavoriteIcon />
              </IconButton>
            </Box>
          )}
          <Paper
            onSubmit={handleSubmit}
            className={classes.paper}
            component="form"
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
          >
            <InputBase
              size="small"
              fullWidth
              value={gameName ?? ''}
              sx={{ ml: 1, flex: 1 }}
              disabled={activeIconMenu === 'resentGames' ? true : false}
              onChange={(e) => handleGameSearch(e)}
              placeholder={t('searchGame')}
              inputProps={{ 'aria-label': 'search game' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
        <Box
          className={cx(styles.games, {
            [styles.loader]: isLoading,
            [styles.noResults]: !activeGames,
          })}
        >
          {isLoading || isLoadingSpecialGames || isLoadingSubProviders ? (
            <Stack
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                zIndex: 4,
              }}
              direction="row"
            >
              <CircularProgress color="error" size={40} />
            </Stack>
          ) : null}

          {activeGames ? (
            activeGames?.map(({ image, id, providerId, isFavorite }: any, index: number) => {
              return (
                <GameCard
                  key={index}
                  imageUrl={image}
                  gameId={id}
                  className={styles.gameItem}
                  providerId={providerId}
                  isFavorite={isFavorite}
                />
              );
            })
          ) : (
            <Typography component={'div'} sx={{ color: 'grey.500', textAlign: 'center' }}>
              {t('noResult')}
            </Typography>
          )}
        </Box>
        {globalTotalPages && globalTotalPages > 1 && page < globalTotalPages ? (
          <Stack className={styles.showMore} spacing={2}>
            <Button onClick={(e) => handleOnClickMore(e, globalTotalPages)}>{t('showMore')}</Button>
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};
