// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import x1 from 'assets/banners/x1.webp';
import x0 from 'assets/banners/x0.webp';
import x2 from 'assets/banners/x2.webp';
import x3 from 'assets/banners/x3.webp';
import x01 from 'assets/banners/x01.webp';
import x02 from 'assets/banners/x02.webp';
import x03 from 'assets/banners/x03.webp';
import x001 from 'assets/banners/x001.webp';
import x002 from 'assets/banners/x002.webp';
import x003 from 'assets/banners/x003.webp';
import x0001 from 'assets/banners/x0001.webp';
import x0002 from 'assets/banners/x0002.webp';

import { Bonuses, Wallet, Games, Telegram } from 'assets/icons/menuIcons/index';
import { useEffect, useState } from 'react';
import { ISelectedBanner } from 'components/sections/topSection';
import cx from 'classnames';
import styles from './carouselVertical.module.scss';
import { links } from 'constants/common';

export const carouselItems = [
  {
    id: '1',
    itemName: 'games',
    icon: Games,
    bannerImage: [x3, x1, x2],
    titleText: [
      // 'Лучшие подборки Лайв-игр!',
      'Топовые игры',
      'Cамые популярные игры',
      'Высокая отдача в ретро играх',
    ],
    secondaryText: ['От провайдера Hacksaw', '', ''],
    button: [
      // {
      //   type: 'sensitiveLink',
      //   buttonColor: 'red',
      //   text: 'Играть',
      //   link: '/live',
      //   isSensitive: true,
      // },
      {
        type: 'gameListInSlots',
        isSensitive: true,
        buttonColor: 'red',
        text: 'Перейти в игры',
        link: '/slots',
        state: { categoryId: 131 },
        // game: { gameIdMobile: 33147, providerId: 3, gameId: 33147 },
      },
      {
        buttonColor: 'red',
        text: 'Популярные игры',
        link: '/statistics',
        routeData: { activeTab: '0' },
      },
      {
        buttonColor: 'red',
        text: 'Играть',
        link: '/category/25',
      },
    ],
  },
  {
    id: '2',
    itemName: 'telegram',
    icon: Telegram,
    bannerImage: [x03, x01, x02],
    titleText: [
      'Подпишись на наш Телеграмм канал и получи Бонусы!',
      'Круглосуточная связь ',
      'Персональные менеджеры',
    ],
    secondaryText: ['', 'с нашими сотрудниками в телеграмм', ''],

    button: [
      {
        type: 'external',
        buttonColor: 'red',
        text: 'Перейти в Телеграмм',
        link: links.TG_CHANNEL,
      },
      {
        type: 'external',
        buttonColor: 'red',
        text: 'Перейти в Телеграмм',
        link: links.TG_SUPPORT,
      },
      {
        type: 'tooltip',
        buttonColor: 'red',
        text: 'Связаться с менеджером',
        links: [
          { name: 'VK менеджер', link: links.VK_MANAGER },
          { name: 'Е-майл', link: links.MAIL },
          { name: 'Телеграмм', link: links.TG_MANAGER },
        ],
      },
    ],
  },
  {
    // [
    //     'Бонусы каждый день на нашем телеграмм канале',
    //     'Подтвердите соц. сети и получите Бонус ',
    //     'Колесо фортуны каждый день до 1000 руб',
    //     'Индивидуальные бонусы от персонального менеджера',
    //   ],
    id: '3',
    itemName: 'bonuses',
    titleText: ['Колесо Фортуны', 'Уровни Магнит - Х', 'Мульти-промокоды'],
    icon: Bonuses,
    bannerImage: [x001, x003, x002],
    secondaryText: [
      'Крути колесо и выигрывай ежедневные бонусы до 1000 рублей!',
      'Повышай уровень, открывай новые возможности и получай еще больше кэшбэков и бонусов!',
      'Розыгрыш Мульти-промокодов каждый день в нашем канале!',
    ],

    button: [
      {
        type: 'sensitiveLink',
        isSensitive: true,
        buttonColor: 'green',
        text: 'Колесо',
        link: '/wheel',
      },
      {
        type: 'openModal',
        modal: { modalName: 'levels', activeTab: '0' },
        isSensitive: true,
        buttonColor: 'green',
        text: 'Уровни',
        link: '',
      },
      {
        type: 'external',
        buttonColor: 'red',
        text: 'Присоединиться',
        link: links.TG_CHANNEL,
      },
    ],
  },
  {
    id: '4',
    itemName: 'wallet',
    icon: Wallet,
    bannerImage: [x0001, x0002],
    titleText: ['Вы можете пополнить баланс через нашего оператора', 'Вывод средств до 60 минут'],
    secondaryText: [''],
    button: [
      {
        buttonColor: 'red',
        type: 'external',
        text: 'Телеграмм',
        link: links.X_PAY,
      },
      {
        type: 'openModal',
        modal: { modalName: 'deposit', activeTab: '2' },
        isSensitive: true,
        buttonColor: 'green',
        text: 'Вывод',
        link: '',
      },
    ],
  },
];

interface CarouselVertical {
  isCategorypage?: boolean;
  onChange?: () => void;
  lineStatus: string;
}

export function CarouselVertical({ onChange, lineStatus }: CarouselVerticalProps) {
  const [selectedItem, setSelectedItem] = useState<ISelectedBanner>(carouselItems[0]);

  useEffect(() => {
    onChange?.(selectedItem);
  }, [selectedItem]);

  return (
    <Box className={styles.main_container}>
      <Swiper
        modules={[Autoplay, Navigation, Pagination, A11y]}
        direction={lineStatus != 'horizontal' ? 'vertical' : 'horizontal'}
        slidesPerView={4}
        spaceBetween={lineStatus != 'horizontal' ? -64 : 0}
        navigation
        scrollbar={{ draggable: false }}
        className={styles.sliderWrapperVertical}
      >
        {carouselItems.map(
          ({ icon, itemName, bannerImage, titleText, secondaryText, button }, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{ cursor: 'pointer' }}
                className={cx({ [styles.img_style]: selectedItem.itemName === itemName })}
              >
                <img
                  src={icon}
                  onClick={() =>
                    setSelectedItem((curr) => ({
                      ...curr,
                      itemName,
                      bannerImage,
                      titleText,
                      secondaryText,
                      button,
                    }))
                  }
                  width="32px"
                  alt="bannerIcon"
                />
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </Box>
  );
}
