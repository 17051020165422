import { Box, Button } from '@mui/material';
import cx from 'classnames';
import Backdrop from '@mui/material/Backdrop';
import { useAuthContext } from 'context/auth/authContext';
import { useTranslation } from 'react-i18next';
import { activeTab, modalTypesObj } from 'components/navBar/navBar';
import { useModalContext } from 'context/modal/modalContext';
import { useDispatch } from 'react-redux';
import { setWelcome } from 'redux/actions/welcomeAction';
import styles from './welcome.module.scss';

export const Welcome = () => {
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const { openModalWithData } = useModalContext();
  const { t } = useTranslation('welcome');

  const handelClick = () => {
    dispatch(setWelcome(null));
    openModalWithData(modalTypesObj['myBonuses'], { activeTab: activeTab['bonuses'] });
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <Box className={styles.main}>
        <Box className={styles.banner}>
          <section className={cx(styles.wrapper, styles.anounce__freespins)}>
            <div className={styles.anoounce__left}>
              <h2 className={styles.anounce__title}>
                Добро{' '}
                <span className={styles.anounce__highlight}>
                  {/* <img className={styles.anounce__highlight_icon} src={crypto} alt="crypto" />{' '} */}
                  пожаловать в Магнит-Х!
                </span>
              </h2>
              <div className={styles.anounce__description}>
                <ul className={styles.arrowed_list}>
                  {user?.user?.promoMessages?.length
                    ? user?.user?.promoMessages
                        .filter((el: any) => el?.type !== 0)
                        .map((el: any, index: number) => {
                          if (el?.type === 3) {
                            return (
                              <li key={index}>
                                {t(el.type, { days: el.props.days })}
                                <br />(
                                {el.props?.amounts?.map((elem: any, index: number) => {
                                  return <span key={index}> {elem} руб. </span>;
                                })}
                                )
                              </li>
                            );
                          }
                          return (
                            <li key={index}>
                              {t(el.type, { amount: el.props.amount, days: el.props.days })}
                            </li>
                          );
                        })
                    : null}
                  <li>Бонусы за подписку на Telegram и ВК! </li>
                  <li>Бонус за подтверждение почты!</li>
                </ul>
              </div>
              <span className={styles.anounce__highlight_small}>
                Вейджер ×2 , максимальный вывод от бонуса 25.000 руб.
              </span>
            </div>
            <div className={styles.anounce__image} />
          </section>
          <Box className={styles.footer}>
            <Button className={styles.btn} onClick={handelClick}>
              Активировать
            </Button>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
};
