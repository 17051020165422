// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconClose } from 'components/closeButton/closeButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { ReactComponent as DisLikeIconEmpty } from 'assets/icons/middle_finger_empty.svg';
import { ReactComponent as DisLikeIconFull } from 'assets/icons/middle_finger_full.svg';
import { ReactComponent as FullScreenIcon } from 'assets/icons/fullscreen.svg';
import { useEffect, useRef, useState } from 'react';
import { useGame } from 'hooks/playGames/useGame';
import { routing } from 'constants/routing';
import { useTranslation } from 'react-i18next';
import common from 'styles/colors.module.scss';
import color from 'styles/colors.module.scss';
import Video from 'assets/video/intro.mp4';
import LoaderWithLogo from 'components/loader/loaderWithLogo';
import { GameSession } from 'components/modals/gameSession/gameSession';
import { style } from 'components/modals/mainModal';
import styles from './playLiveGame.module.scss';

export const PlayLiveGame = () => {
  const { t } = useTranslation('common');
  const containerRef = useRef<HTMLIFrameElement>(null);
  const container: HTMLIFrameElement = containerRef.current;
  const navigate = useNavigate();
  const { playGame, closeGame, changeGameFavoriteStatus, changeGameUnlikedStatus } = useGame();
  const { state } = useLocation();
  const [iframeUrl, setIframeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingIntro, setIsLoadingIntro] = useState(false);
  const [image, setImage] = useState('');
  const [gameName, setGameName] = useState('');
  const [favoriteCount, setGameIsFavoriteCount] = useState('');
  const [unlikedCount, setGameUnlikedCount] = useState('');
  const [isLiked, setIsLiked] = useState<boolean>(state?.isLiked ?? false);
  const [needOpenModal, setNeedOpenModal] = useState(false);
  const [isContinueGame, setContinueGame] = useState(false);
  const [isCloseGame, setCloseGame] = useState(false);
  const [data, setDataForModal] = useState({});
  const [isUnliked, setIsUnliked] = useState<boolean>(state?.isUnliked ?? false);

  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };
  let timeOutId: ReturnType<typeof setTimeout>;
  let timeOutForLoading: ReturnType<typeof setTimeout>;

  const [sessionId, setSessionId] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleClose = async () => {
    const res = await closeGame(sessionId);
    if (res?.status === 200) {
      navigate(`${routing.home}`, {
        state: { ...state },
      });
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen();
      } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen();
      } else if (container.msRequestFullscreen) {
        container.msRequestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }

    timeOutId = setTimeout(() => {
      setIsFullScreen(document.fullscreenElement === containerRef.current);
    }, 100);
  };

  const updateUnLikedStatus = async (status: boolean) => {
    const res = await changeGameUnlikedStatus(state.gameId, status);
    if (res?.status === 200) {
      setIsUnliked(status);
      setIsLiked(false);
      if (status && favoriteCount != '0' && isLiked) {
        setGameIsFavoriteCount(`${+favoriteCount - 1}`);
        await changeGameFavoriteStatus(state.providerId, state.gameId, { isFavorite: false });
      }
      if (status) {
        setGameUnlikedCount(`${+unlikedCount + 1}`);
      } else if (!status && +unlikedCount !== 0) {
        setGameUnlikedCount(`${+unlikedCount - 1}`);
      }
    }
  };

  const updateFavoriteStatus = async (like: boolean) => {
    const body = { isFavorite: like };
    const res = await changeGameFavoriteStatus(state.providerId, state.gameId, body);
    if (res?.status === 200) {
      setIsLiked(like);
      setIsUnliked(false);
      if (like && unlikedCount != '0' && isUnliked) {
        setGameUnlikedCount(`${+unlikedCount - 1}`);
        await changeGameUnlikedStatus(state.gameId, false);
      }
      if (like) {
        setGameIsFavoriteCount(`${+favoriteCount + 1}`);
      } else if (!like && +favoriteCount !== 0) {
        setGameIsFavoriteCount(`${+favoriteCount - 1}`);
      }
    }
  };

  const onHandleCloseGame = () => {
    setNeedOpenModal(false);
    setDataForModal({});
    setCloseGame(true);
    setIsLoadingIntro(true);
  };

  const onHandleContinueGame = () => {
    setNeedOpenModal(false);
    setDataForModal({});
    setContinueGame(true);
    setIsLoadingIntro(true);
  };

  const handleOnEnd = () => {
    setIsLoadingIntro(false);
    setIsLoading(true);
    if (device !== 'Desktop') {
      window.location.replace(iframeUrl);
    } else {
      // toggleFullscreen();
      timeOutForLoading = setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }
  };

  useEffect(() => {
    if (state) {
      const getGameData = async (gameId: string, body: { balanceId: string }) => {
        const query = '?isLive=true';
        const res = await playGame(gameId, body, query);
        if (res?.status === 201) {
          setIsLoadingIntro(true);
          const { data } = res;
          setIframeUrl(data.launchURL);
          setSessionId(data.id);
          setImage(data.game.image);
          setGameIsFavoriteCount(data.game.userFavoriteGamesCount);
          setIsLiked(data.game.isFavorite);
          setIsUnliked(data.game.isUnliked);
          setGameUnlikedCount(data.game.userUnlikedGamesCount);
        } else if (res?.status === 409) {
          const { data } = res;
          setNeedOpenModal(true);
          setDataForModal({
            ...data.game,
            sessionId: data.id,
            isOpenGame: true,
            balance: data.balance,
          });
        }
      };
      if (state?.gameId !== undefined && state?.balanceId !== undefined) {
        const body = { balanceId: state?.balanceId, denomination: state?.denomination };
        getGameData(state.gameId, body);
        setImage(state.image);
        setGameName(state.name);
      }
    } else {
      navigate(routing.home);
    }

    return () => {
      clearTimeout(timeOutForLoading);
      clearTimeout(timeOutId);
    };
  }, [state, isContinueGame, isCloseGame]);

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box className={styles.play_games_container}>
          <Box className={styles.iframe}>
            <Box className={styles.content} ref={containerRef}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pr: isFullScreen ? 1 : 0 }}>
                {isFullScreen && (
                  <FullscreenExitIcon
                    onClick={toggleFullscreen}
                    sx={{ cursor: 'pointer', mt: 1, fontSize: '35px' }}
                  />
                )}
                {(sessionId || iframeUrl) && device === 'Desktop' ? (
                  <IconClose className={styles.iframe_close_btn} onClose={handleClose} />
                ) : null}
              </Box>
              {isLoadingIntro ? (
                <Box className={styles.video_block}>
                  <video
                    width="100%"
                    height="auto"
                    autoPlay
                    muted
                    onEnded={handleOnEnd}
                    playsInline
                  >
                    <source src={Video} type="video/mp4" />
                  </video>
                </Box>
              ) : (
                <>
                  {isLoading ? <LoaderWithLogo isOpen={isLoading} /> : null}
                  {device === 'Desktop' ? (
                    <iframe style={{ border: 'none' }} src={iframeUrl} allowFullScreen seamless />
                  ) : null}
                </>
              )}
            </Box>
            {device === 'Desktop' ? (
              <Box className={styles.fullscreen}>
                <FullScreenIcon onClick={toggleFullscreen} />{' '}
                <IconButton
                  aria-label="add to favorites"
                  sx={{ color: '#fff' }}
                  onClick={() => updateFavoriteStatus(!isLiked)}
                >
                  {isLiked ? (
                    <FavoriteIcon sx={{ width: '18px' }} />
                  ) : (
                    <FavoriteBorderIcon sx={{ width: '18px' }} />
                  )}
                </IconButton>
                <Typography
                  component={'span'}
                  color={common.mainOrange}
                >{`(${favoriteCount})`}</Typography>
                <IconButton
                  aria-label="add to disliked"
                  sx={{ color: '#fff' }}
                  onClick={() => updateUnLikedStatus(!isUnliked)}
                >
                  {isUnliked ? <DisLikeIconFull /> : <DisLikeIconEmpty />}
                </IconButton>
                <Typography
                  component={'span'}
                  color={common.mainOrange}
                >{`(${unlikedCount})`}</Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      {needOpenModal ? (
        <Modal className={styles.mainModal} open={needOpenModal} sx={{ minWidth: 400 }}>
          <Box
            sx={{
              ...style,
              bgcolor: color.secondaryBlue,
              minHeight: '260px',
              padding: '24px',
            }}
            className={styles.content}
          >
            <GameSession
              isLive
              onCloseGame={onHandleCloseGame}
              onContinueGame={onHandleContinueGame}
              props={{ data }}
              isLiveGame
            />
          </Box>
        </Modal>
      ) : null}
    </>
  );
};
