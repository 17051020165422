import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BonusCarousel from 'components/bonusCard/carouselBonus';
import { useMemo } from 'react';
import styles from './gameCarousel.module.scss';

interface GameCarouselProps {
  onClick: (id: string) => void;
  spinList: any[];
}

export const GameCarousel = ({ onClick, spinList }: GameCarouselProps) => {
  const { t } = useTranslation('bonus');

  const handleOnClick = async (id: string) => {
    onClick(id);
  };

  const cards = useMemo(() => {
    return spinList.map((el: any, index: number) => {
      return {
        key: index,
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <img src={el.image} width={80} />
            <Button size="small" className={styles.btn} onClick={() => handleOnClick(el.id)}>
              Играть
            </Button>
          </Box>
        ),
      };
    });
  }, [spinList]);

  return (
    <Box className={styles.main}>
      {spinList.length < 3 ? (
        <Box sx={{ display: 'flex', height: '200px' }}>
          {spinList.map((el: any, index: number) => {
            return (
              <Box
                key={index}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', m: 2 }}
              >
                <img src={el.image} width={80} />
                <Button size="small" className={styles.btn} onClick={() => handleOnClick(el.id)}>
                  Играть
                </Button>
              </Box>
            );
          })}
        </Box>
      ) : (
        <BonusCarousel
          cards={[...cards]}
          height="200px"
          width="300px"
          offset={2}
          showArrow={true}
        />
      )}
    </Box>
  );
};
