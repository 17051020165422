import { combineReducers } from 'redux';
import { currencyReducer } from './currencyReducer';
import { languageReducer } from './languageReducer';
import { deviceTypeReducer } from './deviceTypeReducer';
import { categoriesReducer } from './categoriesReducer';
import { siteConfigsReducer } from './configsReducer';
import { userReducer } from './userReducer';
import { providersReducer, providersReducerWithoutGames } from './providersReducer';
import { topWinnersReducer } from './topWinnersReducer';
import { welcomeReducer } from './welcomeReducer';
import {unUsedSpinsReducer} from './spinsReducer'

const reducers = combineReducers({
  siteCurrencies: currencyReducer,
  siteLanguages: languageReducer,
  device: deviceTypeReducer,
  categories: categoriesReducer,
  siteConfigs: siteConfigsReducer,
  userData: userReducer,
  providers: providersReducer,
  providersWithoutGames: providersReducerWithoutGames,
  topWinnerList: topWinnersReducer,
  welcome: welcomeReducer,
  unUsedSpins: unUsedSpinsReducer
});

export default reducers;
