import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import GameService from 'services/game.service';
import { useModalContext } from 'context/modal/modalContext';
import { useUser } from 'hooks/user/useUser';
import { FIELD_NAME_GAME } from 'types/game';
import { useAuthContext } from 'context/auth/authContext';

export const useGame = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { removeUser } = useUser();
  const { openModalWithData } = useModalContext();
  const [error, setError] = useState('');
  const { userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const { setHasAccessToken } = useAuthContext();
  const initHeaders = generateHeaders(userToken());

  const getLiveGame: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await GameService.getLiveGames(headers, query);

      return data;
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getLiveGame(query, newHeader);
        } else {
          console.log('LOG____> playGame', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const playGame: any = async (
    gameId: string,
    body: { balanceId: string },
    query: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameService.getGameData(headers, gameId, body, query);
      if (status === 409) {
        setError(status + '');
      }
      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await playGame(gameId, body, query, newHeader);
        } else {
          console.log('LOG____> playGame', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const searchGame: any = async (
    name: string,
    query?: string,
    forPublicReguest?: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status, totalPages },
      } = await GameService.searchGame(headers, name, query, forPublicReguest);

      return { status, data, totalPages };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await searchGame(name, query, forPublicReguest, newHeader);
        } else {
          console.log('LOG____> playGame', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const playGameDemo: any = async (providerId: string, gameId: string) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameService.getGameDemoData(providerId, gameId);
      setIsLoading(false);
      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { data, message, status },
          },
        } = err;
        if (status === 409) {
          setError(status + '');
          openModalWithData(FIELD_NAME_GAME.GAME_SESSION_NEED_OPEN_GAME, data);
        } else {
          const result = await errorHandler(status, message);
          const { newHeader, needLogout } = result ?? {};
          if (needLogout) {
            removeUser();
          } else if (!needLogout && newHeader) {
            return await playGame(providerId, gameId);
          } else {
            console.log('LOG____> playGame', message);
            setError(message);
          }
        }
      }
    }
  };

  const changeGameFavoriteStatus: any = async (
    providerId: string,
    gameId: string,
    body: any,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameService.updateFavoriteGameStatus(headers, providerId, gameId, body);
      setIsLoading(false);
      return { data, status };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;
        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await changeGameFavoriteStatus(providerId, gameId, body, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setHasAccessToken(true);
    }
  };

  const changeGameUnlikedStatus: any = async (
    gameId: string,
    isUnliked: any,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      console.log(headers);
      const {
        data: { data, status },
      } = isUnliked
        ? await GameService.addUnlikedGame(headers, gameId)
        : await GameService.deleteUnlikedStatus(headers, gameId);
      setIsLoading(false);
      return { data, status };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;
        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await changeGameUnlikedStatus(gameId, isUnliked, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setHasAccessToken(true);
    }
  };

  const closeGame: any = async (sessionId: string, headers = initHeaders) => {
    if (sessionId)
      try {
        setIsLoading(true);
        const {
          data: { data, status },
        } = await GameService.closeGame(headers, sessionId);
        setIsLoading(false);
        return { status, data };
      } catch (err: any) {
        if (err.response) {
          const {
            response: {
              data: { message, status },
            },
          } = err;

          const result = await errorHandler(status, message);
          const { newHeader, needLogout } = result ?? {};
          if (needLogout) {
            removeUser();
          } else if (!needLogout && newHeader) {
            return await closeGame(sessionId, newHeader);
          } else {
            console.log('LOG____> closeGame', message);
            setError(message);
          }
        }
      } finally {
        setHasAccessToken(true);
      }
  };

  const continueGame: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameService.continueGame(headers);
      setIsLoading(false);
      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await continueGame(newHeader);
        } else {
          console.log('LOG____> continueGame', message);
          setError(message);
        }
      }
    } finally {
      setHasAccessToken(true);
    }
  };

  const getSession: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameService.getSession(headers);

      if (status === 200 && data) {
        openModalWithData(FIELD_NAME_GAME.GAME_SESSION, data);
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getSession(newHeader);
        } else {
          console.log('LOG____> getSession', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const getSessionWhenLogout: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameService.getSession(headers);
      setIsLoading(false);
      if (status === 200) {
        return data;
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getSessionWhenLogout(newHeader);
        } else {
          console.log('LOG____> getSession', message);
          setError(message);
        }
      }
    }
  };

  return {
    isLoading,
    searchGame,
    closeGame,
    continueGame,
    changeGameFavoriteStatus,
    getSessionWhenLogout,
    changeGameUnlikedStatus,
    playGameDemo,
    playGame,
    getSession,
    getLiveGame,
    error,
  };
};
