import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/auth/authContext';
import { TopSection } from 'components/sections/topSection';
import { ReactComponent as ProviderIcon } from 'assets/icons/providorIcon.svg';
import { ProvidersCarousel } from 'components/carousel/providersCarousel';
import { useCategory } from 'hooks/categoryGames/useCategory';
import { Carousel } from 'components/carousel/carousel';
import Icon1 from 'assets/icons/defaultMenuIcons/1.svg';
import Icon2 from 'assets/icons/defaultMenuIcons/2.svg';
import Icon3 from 'assets/icons/defaultMenuIcons/3.svg';
import Icon4 from 'assets/icons/defaultMenuIcons/4.svg';
import Icon5 from 'assets/icons/defaultMenuIcons/5.svg';
import Icon6 from 'assets/icons/defaultMenuIcons/6.svg';
import Icon7 from 'assets/icons/defaultMenuIcons/7.svg';
import Icon8 from 'assets/icons/defaultMenuIcons/8.svg';
import Icon9 from 'assets/icons/defaultMenuIcons/9.svg';
import Icon10 from 'assets/icons/defaultMenuIcons/10.svg';
import Icon11 from 'assets/icons/defaultMenuIcons/11.svg';
import Icon12 from 'assets/icons/defaultMenuIcons/12.svg';
import Icon13 from 'assets/icons/defaultMenuIcons/13.svg';
import { getRandomNum } from 'utils/utils';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SearchGame } from 'components/searchGame/searchGame';
import { AdsCarousel } from 'components/carousel/ads/adsCarousel';
import { adsList } from 'components/carousel/ads/mocked_data';
import styles from './games.module.scss';

const defaultCategoryIcons = {
  '1': Icon1,
  '2': Icon2,
  '3': Icon3,
  '4': Icon4,
  '5': Icon5,
  '6': Icon6,
  '7': Icon7,
  '8': Icon8,
  '9': Icon9,
  '10': Icon10,
  '11': Icon11,
  '12': Icon12,
  '13': Icon13,
} as { [x: string]: string };

export const defaultIcon = () => {
  const key = getRandomNum(1, 13);
  return defaultCategoryIcons[key];
};

export const GamesPage = () => {
  const { t } = useTranslation('common');
  const { user, hasAccessToken } = useAuthContext();
  const [showCategory, setShowCategory] = useState(true);
  const navigate = useNavigate();
  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };
  const { categories } = useSelector<any>((state) => state.categories) as {
    categories: any;
  };
  const { isLoading, getAllCategoryAndAllGames } = useCategory();
  // const providers = useSelector<any>((state) => state.providers) as any[];

  const handleonShowAll = (id: string) => {
    navigate(`${`/slots`}`, {
      state: { categoryId: id },
    });
  };

  const handleHideCategory = (show: boolean) => {
    setShowCategory(!show);
  };

  const generateQuery = (device: string) => {
    const isMobile = device === 'Mobile';
    return isMobile
      ? `isMobile=${isMobile}&isGameCategoryMobile=${isMobile}`
      : 'isDesktop=true&isGameCategoryDesktop=true';
  };

  const getData = async (user: any, query: string) => {
    if (!user) {
      await getAllCategoryAndAllGames(query);
    } else if (hasAccessToken) {
      await getAllCategoryAndAllGames(query, '/user?');
    }
  };

  useEffect(() => {
    const query = generateQuery(device);
    if (device) getData(user, query);
  }, [user, device]);

  // //**Todo remove this after  some changes*/
  // useEffect(() => {
  //   const query = generateQuery(device);
  //   if (!categories.length) getData(user, query);
  // }, [categories]);

  return (
    <Box>
      <Box sx={{ width: '100vw' }}>
        <TopSection />
      </Box>
      {device === 'Desktop' ? <SearchGame hideCategory={handleHideCategory} user={user} /> : null}
      {showCategory || device !== 'Desktop' ? (
        <>
          {categories
            .filter((el: any) => el.isPrimaryShow)
            ?.map((category: any, index: number) => {
              return (
                <Box className={styles.category_section} key={index}>
                  <Box sx={{ display: 'flex' }}>
                    <Box className={styles.link}>
                      <Link
                        to={`/slots`}
                        state={{ categoryId: category.id }}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <img
                          width="24"
                          height="auto"
                          src={category.image ?? defaultIcon()}
                          alt="game"
                        />
                        <Typography align="left" sx={{ ml: 2 }}>
                          {category.title}
                        </Typography>
                      </Link>
                    </Box>
                    <Box className={styles.category_show_all}>
                      <Button
                        size="small"
                        className="App-btn red"
                        sx={{ m: 2, color: '#fff', minWidth: '120px' }}
                        onClick={() => handleonShowAll(category.id)}
                      >
                        {t('showAll')}
                      </Button>
                    </Box>
                  </Box>

                  <Box className={styles.category_carousel}>
                    <Carousel
                      carouselItems={[...category.games]}
                      isLoading={isLoading && !categories?.length}
                    />
                  </Box>
                </Box>
              );
            })}
          <Box className={styles.category_section}>
            {/* <Box sx={{ display: 'flex' }} className={styles.link_provider}>
                <ProviderIcon />
                <Typography align="left" sx={{ ml: 2 }}>
                  {t('providers')}
                </Typography>
              </Box> */}
            <Box className={styles.ads}>
              <AdsCarousel carouselItems={[...adsList]} />
            </Box>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
