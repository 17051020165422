import http2 from 'http2-common';
import http from '../http-common';
import { SITE_ID } from 'services/site.service';
// import http1 from '../http1-common';

class GameDataService {
  closeGame = (headers, sessionId) => {
    return http2.put(`/games/session/${sessionId}/end`, {}, { headers });
  };

  getGameData = (headers, gameId, body, query = '') => {
    return http2.post(`/games/${gameId}/session${query}`, body, { headers });
  };

  getGameDemoData = (providerId, gameId) => {
    return http.post(
      `/game-providers/${providerId}/games/${gameId}/init-demo?siteId=${SITE_ID}`,
      {},
    );
  };

  updateFavoriteGameStatus = (headers, providerId, gameId, body) => {
    return http.put(`/game-providers/${providerId}/games/${gameId}/favorite`, body, { headers });
  };

  addUnlikedGame = (headers, gameId) => {
    return http2.put(`/games/${gameId}/unlike`, {}, { headers });
  };

  searchGame = (headers, name, query = '', forPublicReguest = '?') => {
    return http2.get(`/games${forPublicReguest}name=${name}${query}`, { headers });
  };

  deleteUnlikedStatus = (headers, gameId) => {
    return http2.delete(`/games/${gameId}/unlike`, { headers });
  };

  continueGame = (headers) => {
    return http.post(`/game-providers/session/continue?siteId=${SITE_ID}`, {}, { headers });
  };

  getSession = (headers) => {
    return http.get(`/game-providers/session?siteId=${SITE_ID}`, { headers });
  };

  getAllGameList = (headers, subProviderId, query) => {
    return http2.get(`/games?subProviderId=${subProviderId}${query}`, { headers });
  };

  getAllGameListByQuery = (headers, forPublic = '?', query) => {
    return http2.get(`/games${forPublic}${query}&sortBy=order&sortDir=ASC`, { headers });
  };

  getLiveGames = (headers, query) => {
    return http2.get(`/games/live${query}`, { headers });
  };

  playSpin = (headers, freeSpinId ) => {
    return http2.post(`users/free-spins/${freeSpinId}/use`,{}, { headers });
  };
}

const GameService = new GameDataService();

export default GameService;
