import { useState } from 'react';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useUser } from './useUser';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { generateHeaders, joinMessage } from 'utils/utils';
import UserService from 'services/user.service';
import { useDispatch } from 'react-redux';
import { setUserData, updateUserDataForFields } from 'redux/actions/userDataAction';
import { IUserInfo } from 'types/user';
import BonusService from 'services/bonus.service';
import { useAuthContext } from 'context/auth/authContext';
import { setUnUsedSpinsData } from 'redux/actions/spinActions';

export const useUserData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bonusData, setBonusData] = useState<any>([]);
  const [spinsData, setSpinsData] = useState<any>([]);
  const [error, setError] = useState('');
  const { userToken, updateData } = useStorage();
  const { removeUser } = useUser();
  const { errorHandler } = useApiErrors();
  const dispatch = useDispatch();
  const { setHasAccessToken } = useAuthContext();

  const initHeaders = generateHeaders(userToken());

  const getUserWheelDiff: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await UserService.getUserWheelDiff(headers);
      if (status === 200) {
        dispatch(updateUserDataForFields({ ...data }));
        updateData(data);
        return { data, status };
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUserWheelDiff(newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const getUsersFavoriteGames: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await UserService.getUsersFavoriteGames(headers, query);
      return { data: data.userFavoriteGames, status, totalPages: data.totalPages };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUsersFavoriteGames(query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const getUserWheelBonus: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await UserService.getUserWheelBonus(headers);
      if (status === 200) {
        return { data, status };
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUserWheelBonus(newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const getUsersRecentGames: any = async (forModal?: boolean, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await UserService.getUsersRecentGames(headers, forModal);
      setIsLoading(false);
      return { data, status };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUsersRecentGames(forModal, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const getUserData: any = async (headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await UserService.getUserData(headers);
      if (status === 200) {
        dispatch(setUserData(data));
      }
    } catch (error: any) {
      if (error.response) {
        const {
          data: { message, status },
        } = error.response;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUserData(newHeader);
        } else {
          console.log('LOG____> getUserData', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const getUserBonuses: any = async (headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await BonusService.getUserBonusesData(headers);
      if (status === 200) {
        setBonusData(data);
      }
    } catch (err: any) {
      const {
        data: { message, status },
      } = err.response;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        removeUser();
      } else if (!needLogout && newHeader) {
        return await getUserBonuses(newHeader);
      } else {
        console.log('getUserBonuses>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const getUserSpins: any = async (query: string, type: string, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { gameFreeSpins, totalItems, totalPages },
          status,
        },
      } = await BonusService.getUserSpinsData(headers, query);
      if (status === 200) {
        if (type === 'unUsed') {
          dispatch(setUnUsedSpinsData(gameFreeSpins));
        } else {
                 return { spins: gameFreeSpins ?? [], totalItems, totalPages };

        }
      }
    } catch (err: any) {
      const {
        data: { message, status },
      } = err.response;
      const result = await errorHandler(status, message);
      const { newHeader, needLogout } = result ?? {};
      if (needLogout) {
        removeUser();
      } else if (!needLogout && newHeader) {
        return await getUserSpins(query,'' ,newHeader);
      } else {
        console.log('getUserBonuses>', message);
        setError(message);
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const changeUserData: any = async (userId: number, body: IUserInfo, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { status },
      } = await UserService.updateUserData(headers, userId, body);
      return { status };
    } catch (err: any) {
      if (err.response) {
        const {
          data: { error, message, status },
        } = err.response;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await changeUserData(userId, body, newHeader);
        } else {
          const errorMessage = joinMessage(error);
          console.log('LOG____changeUserData>', errorMessage);
          setError(errorMessage);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };

  const trasferUserBalance: any = async (body: IUserInfo, headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { status, data },
      } = await UserService.trasferUserBalance(headers, body);
      return { status, data };
    } catch (err: any) {
      if (err.response) {
        const {
          data: { message, status },
        } = err.response;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await trasferUserBalance(body, newHeader);
        } else {
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
      setHasAccessToken(true);
    }
  };
  return {
    isLoading,
    error,
    getUserData,
    getUsersFavoriteGames,
    getUsersRecentGames,
    changeUserData,
    trasferUserBalance,
    getUserWheelBonus,
    getUserWheelDiff,
    getUserBonuses,
    bonusData,
    getUserSpins,
    spinsData,
    resetError: () => setError(''),
  };
};
