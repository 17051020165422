import { Dayjs } from 'dayjs';

export interface User {
  id: string;
  name: string;
  email: string;
  verificationStatus?: string;
  accessToken?: string;
  refreshToken?: string;
  isWheelEnabled: boolean;
  /**TODO write real interface */
  promoMessages: any | null;
  user: IUser;
}

export interface IUser {
  id: string;
  balance: IUserBalance[];
  [x: string]: any;
}

export interface IUserBalance {
  id: string;
  type: string;
  amount: number;
}

export interface IUserInfo {
  login?: string;
  email?: string;
  phone?: string;
  birthdayAt?: Dayjs | null;
  name?: string;
  surname?: string;
}

export enum FIELD_NAME_USER_INFO {
  BIRTHDAY_AT = 'birthdayAt',
  EMAIL = 'email',
  PHONE = 'phone',
  LOGIN = 'login',
  FIRST_NAME = 'name',
  LAST_NAME = 'surname',
}

export enum FIELD_NAME_SECURITY {
  OLD_PASSWORD = 'oldPassword',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword',
}
