import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import common from 'styles/colors.module.scss';
import _color from 'styles/colors.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { routing } from 'constants/routing';
import { usePayment } from 'hooks/payment/usePayment';
import { useAuthContext } from 'context/auth/authContext';
import { links } from 'constants/common';
import { useTranslation } from 'react-i18next';
import styles from './manualPayment.module.scss';
import { PROCESS } from 'types/payment';

const init = {
  amount: null,
  paymentId: null,
  requisites: null,
};

const SUCCESS = 'get_pay_in_process_details_success';
const PENDING = 'get_pay_in_process_details_pending';
const CANCElED = 'get_pay_in_process_details_canceled';

function ManualPaymentConfirm() {
  const { state } = useLocation();
  const { t } = useTranslation('payment');
  const navigate = useNavigate();
  const { payInProccesConfirm, payInProccesApprove } = usePayment();
  const { hasAccessToken } = useAuthContext();
  const [timeLeft, setTimeLeft] = useState<number>();
  const [data, setData] = useState({ ...init });
  const [contextText, setContextText] = useState('');
  const controlTime = useRef(0);

  let timeOutId: ReturnType<typeof setTimeout>;
  let timerId: ReturnType<typeof setTimeout>;

  const startCountdown = (num: number) => {
    const savedEndTime = localStorage.getItem('countdownEndTime') as unknown as number;

    if (savedEndTime) {
      const remaining = Math.max(0, Math.floor((savedEndTime - Date.now()) / 1000));
      setTimeLeft(remaining);
    } else {
      const endTime = Date.now() + num * 1000;
      localStorage.setItem('countdownEndTime', endTime + '');
    }

    if (timerId) clearInterval(timerId);

    timerId = setInterval(() => {
      const dd = localStorage.getItem('countdownEndTime') as unknown as number;
      const remaining = Math.max(0, Math.floor((dd - Date.now()) / 1000));
      setTimeLeft(remaining);
      if (remaining <= 0) clearInterval(timerId);
    }, 1000);
  };

  const makeRequest = async (id: string | null) => {
    if (id) {
      startCountdown(600);
      const result = await payInProccesConfirm(id);
      if (result?.status === 200 && result?.message === PENDING) {
        //pendinq
      } else if (result?.status === 200 && result?.message === SUCCESS) {
        redirectToWithDelay('success', t('successDeposit'));
      } else if (result?.status === 403 && result?.message === CANCElED) {
        redirectToWithDelay('error', t('failedDeposit'));
      }
    }
  };

  const changeStatus = async (id: string | null) => {
    if (id) {
      const result = await payInProccesApprove(id);
      if (result?.status === 200) {
        makeRequest(id);
      }
    }
  };

  const redirectTo = () => {
    window.open(links.TG_SUPPORT, '_blank');
  };

  const redirectToWithDelay = (status: string, contentText: string) => {
    clearInterval(timerId);

    timeOutId = setTimeout(() => {
      navigate(`${routing.home}`, {
        state: {
          paymentStatus: status,
          contentText,
        },
      });
    }, 4000);
  };

  useEffect(() => {
    if (state) {
      const { paymentId, type, isPaid } = state;
      if (paymentId && hasAccessToken) {
        if (!isPaid) {
          changeStatus(paymentId);
        } else {
          makeRequest(paymentId);
        }
        setData(state);
        if (type === PROCESS.PHONE) {
          setContextText(`Пожалуйста, подождите, транзакция на сумму ${
            state?.amount ?? ''
          } руб., оправленная по номеру  ${state?.requisites ?? ''} через
              метод СБП, будет потверждена в течение`);
        } else if (type === PROCESS.CARD) {
          setContextText(
            `Пожалуйста, подождите, транзакция на сумму ${
              state?.amount ?? ''
            } руб., оправленная по номеру  карты ${
              state?.requisites ?? ''
            }  будет потверждена в течение`,
          );
        }
      }
    } else {
      navigate(`${routing.home}`);
    }
  }, [state, hasAccessToken]);

  useEffect(() => {
    ++controlTime.current;
    if (!(controlTime.current % 10)) {
      makeRequest(data?.paymentId ?? state?.paymentId);
    }
  }, [timeLeft]);

  useEffect(() => {
    return () => {
      clearInterval(timerId);
      localStorage.removeItem('countdownEndTime');
      clearTimeout(timeOutId);
    };
  }, []);

  return (
    <>
      <Box className={styles.content}>
        <Box className={styles.req_body}>
          <Box className={styles.req_title}>
            <Typography component="h1">
              {'Не закрывайте эту страницу, иначе транзакция может быть утеряна!'}
            </Typography>
          </Box>
          <Box className={styles.req_content}>
            <Typography component="p">{contextText}</Typography>
          </Box>
          {timeLeft ? (
            <Typography
              sx={{
                color: common.error,
                fontWeight: 600,
                textAlign: 'center',
                m: 2,
                fontSize: '1.8rem',
              }}
            >
              {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
            </Typography>
          ) : (
            <Stack sx={{ color: 'grey.500', justifyContent: 'center' }} spacing={2} direction="row">
              <CircularProgress sx={{ color: _color.mainOrange }} />
            </Stack>
          )}

          <Box className={styles.actions}>
            <Button fullWidth className={styles.grey} onClick={redirectTo}>
              Написать в поддержку
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ManualPaymentConfirm;
