import http from '../http-common';
import http2 from '../http2-common';


export const SITE_ID = 1;

class SiteDataService {
  
  getSiteConfiguration = () => http2.get(`/sites`);

  getAllCategoryAndAllGames = (headers, query = 'isDesktop=true&isGameCategoryDesktop=true', user = '?') => {
    return http.get(
      `/site/${SITE_ID}/category-games/all${user}sortBy=order&sortDir=ASC&gameSortBy=order&gameSortDir=ASC&${query}&pageSize=100`, { headers }
    );
  };
}

const SiteService = new SiteDataService();

export default SiteService;
