export const common = {
  YES: 'yes',
  NO: 'no',
  TRUE: 'true',
  FALSE: 'false',
  ASC: 'ASC',
  DESC: 'DESC',
  SITE: 'site',
  NEW: 'new',
  ADD: 'Add',
  APPLY: 'apply',
  CANCEL: 'cancel',
};

export const routeSwitcher = {
  ACCOUNT_PAGE: 'accountsPage',
  SITE_PAGE: 'sitesPage',
  SITE_SETTINGS: 'sitesSettingsPage',
  WELCOME_PAGE: 'welcomePage',
  PERMISSON_MANAGMENT: 'permission managment',
  STATISTICS: 'statistics',
  CHANNEL: 'telegram channel',
  RAFFLE: 'telegram raffle',
  GAMBLING_STATISTICS: 'gambling statistics',
};

export const dialog = {
  WANT_EXIT: 'wantExit',
  CHOOSE_BALANCE: 'chooseBalance',
  SELECT_DENOMINATION: 'selectDenomination',
  WHEEL_TITLE: 'wheelTitle',
  WHEEL_CONTENT: 'wheelDialogContent',
  WHEEL_TITLE_WIN: 'wheelTitleWin',
  WHEEL_CONTENT_WIN: 'wheelDialogContentWin',
  WHEEL_CONTENT_DELAY: 'wheelDialogContentDelay',
  CANCEL_BONUS: 'cancelBonus',
  SPIN_TEXT: 'spinText',
  CANCEL_ACTIVE_BONUS: 'cancelActiveBonus',
  CANCEL_BONUS_MESSAGE: 'cancelBonusContent',
};

type IRESULT = {
  [key: string]: string;
};

export const requestResults: IRESULT = {
  'Something went wrong': 'somethingWentWrong',
  PROMO_ACTIVATE_SUCCESS: 'promoActivateSuccess',
  activate_user_promo_not_found: 'wrongPromo',
  promo_code_limit: 'promoLimit',
  not_enough_deposit: 'promoNotEnough'
};

export const mobileMenuItems = {
  LOBBY: 'lobby',
  STATISTICS: 'statistics',
  SUPPORT: 'support',
};

export const links = {
  TAWK: 'https://tawk.to/chat/678e1e23825083258e07c815/1ii1ibelp',
  TG_SUPPORT: 'https://t.me/vpayqiwi2',
  TG_MANAGER: 'https://t.me/magnitxmanager',
  TG_CHANNEL: 'https://t.me/magnitxofficial',
  VK_CHANNEL: 'https://vk.com/magnitxcasino',
  VK_MANAGER: 'https://vk.com/magnitxofficial',
  MAIL: 'magnitxofficial@gmail.com',
  X_PAY: 'https://t.me/volkPay2'
};

export const deviceSize = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
};
