import { actionTypes } from 'redux/constants/actionTypes';

const initial = null

export const welcomeReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_WELCOME_DATA:
      return payload ? [...payload]: null;
    default:
      return state;
  }
};
