
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';  
import rootReducer from './reducers';  

 
const persistConfig = {
  key: 'root',  
  storage,   
  whitelist: ['categories', 'providers', 'providersWithoutGames' ], 
};
 
const persistedReducer = persistReducer(persistConfig, rootReducer);
 
const store = configureStore({
  reducer: persistedReducer,
  
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,  
    }),
},
window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const persistor = persistStore(store);

export { store, persistor };

