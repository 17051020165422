import { Box, Button, Typography } from '@mui/material';
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import { useRef } from 'react';
import { IBanner, ISelectedBanner } from 'components/sections/topSection';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import TooltipComponent from 'components/tooltip/tooltip';
import { useModalContext } from 'context/modal/modalContext';
import { FIELD_NAME } from 'types/login';
import { useAuthContext } from 'context/auth/authContext';
import { MODAL_TYPES } from 'types/modals';
import { useSelector } from 'react-redux';
import { deviceSize } from 'constants/common';
import styles from './banner.module.scss';

interface BannerProps {
  selectedItem: ISelectedBanner;
}

export const Banner = ({ selectedItem }: BannerProps) => {
  const divData = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { openModalWithData } = useModalContext();
  const { user } = useAuthContext();
  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };

  const handleOnClick = (data: any) => {
    if (data?.type === 'external') {
      window.open(data.link, '_blank');
    } else if (data?.type === 'openModal' || data?.type === 'sensitiveLink') {
      if (data?.isSensitive && !user) {
        openModalWithData(
          FIELD_NAME.LOGIN,
          data?.type === 'sensitiveLink'
            ? {
                redirect: data.link,
              }
            : null,
        );
      } else {
        if (data?.type === 'sensitiveLink') {
          navigate(data.link);
        } else {
          openModalWithData(data?.modal.modalName, { activeTab: data?.modal.activeTab });
        }
      }
    } else if (data?.type === 'playGame') {
      if (data?.isSensitive && user) {
        openModalWithData(MODAL_TYPES.CHOOSE_BALANCE, {
          gameId: device !== deviceSize.DESKTOP ? data?.game?.gameIdMobile : data?.game?.gameId,
          providerId: data?.game?.providerId,
          image: '',
          name: '',
        });
      } else {
        openModalWithData(FIELD_NAME.LOGIN_FOR_PLAY_GAME, {
          gameId: device !== deviceSize.DESKTOP ? data?.game?.gameIdMobile : data?.game?.gameId,
          providerId: data?.game?.providerId,
          image: '',
          name: '',
        });
      }
    } else if (data?.type === 'gameListInSlots') {
      navigate(data.link, { state: data.state });
    } else {
      const state = data
        ? {
            state: {
              ...data,
            },
          }
        : {};

      navigate(data.link, state);
    }
  };

  return (
    <Box className={styles.container_banner} sx={{ margin: '24px 12px' }}>
      <Box className={styles.img_container}>
        <Swiper
          modules={[Autoplay, Navigation, Pagination, A11y]}
          pagination={{ clickable: true }}
          spaceBetween={20}
          autoplay
          speed={1200}
          loop
          slidesPerView={1}
          className={styles.sliderWrapper}
        >
          {selectedItem.bannerImage.map((elm: IBanner, i: number) => {
            return (
              <SwiperSlide key={i}>
                <Box className={styles.info}>
                  {selectedItem.titleText[i] ? (
                    <Typography component="h1" className={styles.title}>
                      {selectedItem.titleText[i]}
                    </Typography>
                  ) : null}

                  {Array.isArray(selectedItem.secondaryText[i]) ? (
                    selectedItem?.secondaryText[i].map((el: string, index: number) => {
                      return (
                        <Typography key={index} component="p" className={styles.no_clip_with_icon}>
                          {el}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography component="p" className={styles.no_clip}>
                      {selectedItem.secondaryText[i]}
                    </Typography>
                  )}
                  {selectedItem?.button[i]?.type === 'tooltip' ? (
                    <TooltipComponent
                      btnText={selectedItem?.button[i]?.text}
                      tooltipContent={selectedItem?.button[i]?.links}
                      wrapClass={cx(styles.btn, {
                        [styles.red]: selectedItem?.button[i]?.buttonColor === 'red',
                        [styles.orange]: selectedItem?.button[i]?.buttonColor === 'orange',
                        [styles.green]: selectedItem?.button[i]?.buttonColor === 'green',
                      })}
                    />
                  ) : selectedItem?.button[i]?.text ? (
                    <Button
                      onClick={() => handleOnClick(selectedItem?.button[i])}
                      className={cx(styles.btn, {
                        [styles.red]: selectedItem?.button[i]?.buttonColor === 'red',
                        [styles.orange]: selectedItem?.button[i]?.buttonColor === 'orange',
                        [styles.green]: selectedItem?.button[i]?.buttonColor === 'green',
                      })}
                    >
                      {selectedItem.button[i].text}
                    </Button>
                  ) : null}
                </Box>
                <Box ref={divData} className={styles.img} sx={{ backgroundImage: `url(${elm})` }} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};
