import { actionTypes } from '../constants/actionTypes';

export const setUnUsedSpinsData = (spins) => {
  return {
    type: actionTypes.SET_UNUSED_SPIN_LIST,
    payload: Array.isArray(spins) ? spins : []
  };
};
  


 