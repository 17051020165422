import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useUser } from 'hooks/user/useUser';
import GameService from 'services/game.service';

export const useSpins = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { removeUser } = useUser();
  const [error, setError] = useState('');
  const { userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const playSpins: any = async (spinId: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await GameService.playSpin(headers, spinId);

      if (status === 200) {
        return { data };
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await playSpins(spinId, newHeader);
        } else {
          console.log('LOG____> playSpins', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    playSpins,
    error,
    resetError: () => setError(''),
  };
};
