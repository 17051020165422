import { Dayjs } from 'dayjs';
import { FIELD_NAME } from 'types/signUp';
import { FIELD_NAME as FIELD } from 'types/login';
import { FIELD_NAME_USER_INFO } from 'types/user';

import {
  minLength,
  isValidEmail,
  isAlphanumericWithSpace,
  isValidBirthdayDate,
  maxLength,
  isAlphanumeric,
  isValidPhonePattern,
} from 'utils/validations/validationTypes';

export const isValidValue = (value: string, name: string, component?: string) => {
  if (value) {
    let isValid = true;
    switch (name) {
      case FIELD_NAME.LOGIN:
        component === FIELD.SIGN_UP
          ? (isValid = isAlphanumeric(value) && minLength(4)(value) && maxLength(16)(value))
          : (isValid = minLength(4)(value) && maxLength(16)(value));
        break;
      case FIELD_NAME.REFERRAL:
        isValid = minLength(5)(value) && maxLength(16)(value);
        break;
      case FIELD_NAME.PHONE:
        isValid = value.length === 17 && isValidPhonePattern(value);
        break;
      case FIELD_NAME.EMAIL:
        isValid = isValidEmail(value) && maxLength(35)(value);
        break;
      case FIELD_NAME.PASSWORD:
        isValid = minLength(7)(value);
        break;
      case FIELD_NAME.CONFIRM_PASS:
        isValid = minLength(7)(value);
        break;
      case FIELD_NAME.PROMO_CODE:
        isValid = isAlphanumericWithSpace(value) && minLength(4)(value);
        break;
      default:
    }
    return isValid;
  }
};

export const isValidValueForUserInfo = (value: string | Dayjs | null, name: string) => {
  if (value) {
    let isValid = true;
    switch (name) {
      case FIELD_NAME_USER_INFO.PHONE:
        isValid = (value as string).length === 17 && isValidPhonePattern(value);
        break;
      case FIELD_NAME_USER_INFO.FIRST_NAME:
        isValid = maxLength(16)(value);
        break;
      case FIELD_NAME_USER_INFO.LAST_NAME:
        isValid = maxLength(16)(value);
        break;
      case FIELD_NAME_USER_INFO.EMAIL:
        isValid = isValidEmail(value);
        break;
      case FIELD_NAME_USER_INFO.BIRTHDAY_AT:
        isValid = isValidBirthdayDate(value);
        break;
      default:
    }
    return isValid;
  }
};
