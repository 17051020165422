import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import { ModalState } from 'context/modal/modalContext';
import { useUser } from 'hooks/user/useUser';
import { dialog } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { setUserData } from 'redux/actions/userDataAction';
import { useDispatch } from 'react-redux';
import { setUnUsedSpinsData } from 'redux/actions/spinActions';
import styles from './logout.module.scss';

interface GameSessionProps {
  onClose: () => void;
  props?: ModalState;
}

export const Logout = ({ onClose, props }: GameSessionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { removeUser } = useUser();
  const dispatch = useDispatch();

  const handleCancel = () => {
    onClose();
  };

  const handleExit = () => {
    /**TODO: discuss this part then */

    // if (props?.data.gameId !== null) {
    //   const res = await closeGame(props?.data.gameId);
    //   if (res?.status === 200) {
    //     removeUser();
    //     onClose();
    //     navigate(`${routing.home}`);
    //   }
    // } else {
    //   removeUser();
    //   onClose();
    //   navigate(`${routing.home}`);
    // }
    removeUser();
    dispatch(setUserData(null));
    dispatch(setUnUsedSpinsData([]));
    onClose();
    navigate(`${routing.home}`);
  };

  return (
    <Box className={styles.content}>
      <Box>
        <Typography sx={{ mb: 2 }} align="center">
          {t(dialog.WANT_EXIT)}
        </Typography>
      </Box>
      <Box className={styles.action}>
        <Button
          size="large"
          type="submit"
          fullWidth
          className={styles.close_btn}
          onClick={handleCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          size="large"
          type="submit"
          fullWidth
          className={styles.playing_btn}
          onClick={handleExit}
        >
          {t('yes')}
        </Button>
      </Box>
    </Box>
  );
};
