import { useEffect, useState } from 'react';
import { Box, Button, Pagination, Stack, Typography } from '@mui/material';
import cx from 'classnames';
import { useUserData } from 'hooks/user/useUserData';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoaderWithBg from 'components/loader/loader';
import { common, dialog } from 'constants/common';
import Dialogs from 'components/dialog/dialogs';
import { useStyles } from 'components/pageLayout/category/category';
import { IBonus } from 'types/bonus';
import _color from 'styles/colors.module.scss';
import { useSpins } from 'hooks/user/useSpins';
import { routing } from 'constants/routing';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from 'context/modal/modalContext';
import styles from './spins.module.scss';

function Spins() {
  const { getUserSpins, isLoading } = useUserData();

  const navigate = useNavigate();
  const { t } = useTranslation('deposit');
  const { t: commonT } = useTranslation('common');
  const { playSpins, isLoading: isLoadingSpins } = useSpins();
  const { closeModal } = useModalContext();

  const [totalPages, setTotalPages] = useState(0);
  const [assumeActiveBonusId, setAssumeActiveBonusId] = useState<string>('');
  const [spinList, setSpinList] = useState<IBonus[]>([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [isOpenCancel, setOpenCancel] = useState(false);

  const navigatePlay = (id: string, data: any) => {
    const state = {
      ...data,
      isSpinPlay: true,
    };
    closeModal();
    navigate(`${routing.game}`, {
      state,
    });
  };

  const handleOnConfirm = async (id: string) => {
    const result = await playSpins(id);
    if (result) {
      navigatePlay(id, result.data);
    }
  };

  const handleOnClick = (type: string, bonusId: number) => {
    if (type === common.APPLY) {
      setOpen(true);
      setAssumeActiveBonusId(bonusId + '');
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getData = async (page: number) => {
    const paramsQuery = `page=${page}&pageSize=10`;

    const res = await getUserSpins(paramsQuery, '');
    if (res) {
      setSpinList(res?.spins);
      setTotalPages(res.totalPages);
    }
  };

  useEffect(() => {
    getData(page);
  }, [page]);

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}> Мои Спины </Box>
      {isLoadingSpins || isLoading ? <LoaderWithBg isOpen={isLoadingSpins || isLoading} /> : null}

      {/* {!activeBonus && bonusList.length ? (
        bonusList.filter((el) => !el.isActivated)?.length ? (
          <Box className={styles.main_bonus_info}>
            <Typography variant="subtitle1" component="p">
              У вас нет использованных спинов, чтобы их использовать нажмите «Играть».
            </Typography>
          </Box>
        ) : (
          <Box className={styles.main_bonus_info}>
            <Typography variant="subtitle1" component="p">
              {t('userBonusInfoNoBonus')}
            </Typography>
          </Box>
        )
      ) : null} */}

      <Box className={styles.bonuses_area}>
        {spinList.length ? (
          spinList.map((elm: any, i: number) => {
            return (
              <Box
                key={i}
                className={cx(styles.bonus_info, {
                  [styles.isActive]: elm.isActive,
                  [styles.isActivated]: elm.isActivated,
                })}
              >
                <Box className={styles.bonus_title_area}>
                  <Box sx={{ flexDirection: 'column', minWidth: '150px' }}>
                    <Box>
                      {elm.isActive && (
                        <CheckCircleIcon sx={{ color: _color.darkGreen, fontSize: '18px' }} />
                      )}
                      <Typography
                        fontWeight={900}
                        component={'span'}
                        fontSize={12}
                        color={elm.isActive ? _color.darkGreen : _color.error}
                      >
                        {elm.isActive
                          ? commonT('bonusIsActive')
                          : elm.isActivated
                            ? ''
                            : 'Неиспользованный'}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography component={'span'} fontSize={'11px'}>
                    {elm.comment}
                  </Typography>
                </Box>
                <Box className={styles.info_amount}>
                  <Box className={styles.item}>
                    <Typography component={'span'} sx={{ fontWeight: 'bolder' }}>
                      Кол.-во спинов:{' '}
                    </Typography>
                    <Typography component={'span'}>{elm.betCount}</Typography>
                  </Box>
                  <Box className={cx(styles.item, styles.weiger_info)}>
                    <Box className={styles.img}>
                      <img src={elm.image} width={80} />
                    </Box>
                    <Box className={styles.img_title}>{elm.gameName} </Box>
                  </Box>
                </Box>

                <Box className={styles.button_area}>
                  <Button
                    sx={{
                      backgroundColor:
                        elm.isActive || elm.isActivated ? _color.lightGrey : _color.darkGreen,
                    }}
                    disabled={elm.isActive || elm.isActivated}
                    onClick={() => handleOnClick(common.APPLY, elm.id)}
                  >
                    Играть
                  </Button>
                  <Button
                    disabled={!elm.isActive || elm.isActivated}
                    sx={{
                      backgroundColor: elm.isActivated ? _color.lightGrey : _color.error,
                    }}
                    // onClick={() => handleOnClick(common.CANCEL, elm.id)}
                  >
                    {commonT('refuse')}
                  </Button>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box className={styles.haveNoBonus_area}>
            <Box className={styles.haveNoBonus_box}>
              <DescriptionIcon />
              <Typography fontSize={'12px'}>{t('haveNoSpins')}</Typography>
            </Box>
          </Box>
        )}

        <Box sx={{ color: 'black' }}>
          <Dialogs
            id={assumeActiveBonusId}
            title={commonT(dialog.CANCEL_BONUS)}
            content={commonT(dialog.SPIN_TEXT)}
            subContent={commonT('subcontent')}
            isOpen={open}
            onClose={() => setOpen(false)}
            onConfirm={handleOnConfirm}
            btnText={commonT('play')}
            ignoreBtnText={commonT('cancel')}
            contentStyle={{ padding: '0 12px' }}
          />
          {isOpenCancel ? (
            <Dialogs
              id={assumeActiveBonusId}
              title={''}
              content={commonT(dialog.CANCEL_ACTIVE_BONUS)}
              subContent={commonT('subcontent')}
              isOpen={isOpenCancel}
              onClose={() => setOpenCancel(false)}
              // onConfirm={handleOnConfirmCancel}
              btnText={commonT('yes')}
              ignoreBtnText={commonT('no')}
              contentStyle={{ padding: '0 12px' }}
            />
          ) : null}
        </Box>
      </Box>
      {!isLoading && totalPages > 1 ? (
        <Stack spacing={2} sx={{ m: 4, justifyContent: 'center', alignItems: 'center' }}>
          <Pagination
            count={totalPages}
            className={classes.ul}
            hideNextButton
            hidePrevButton
            page={page}
            onChange={handleChangePage}
          />
        </Stack>
      ) : null}
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
    </Box>
  );
}

export default Spins;
